import {useState} from "react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar';
import {useNavigate} from "react-router-dom";
import {getUser} from "../services/AuthService";
import {Box, Modal, Stack} from "@mui/material";

export default function ProfilePopover() {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const user = getUser();

    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 48px)',
        maxWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    function logoutUser() {
        localStorage.clear();
        navigate('/login');
    }

    function getFirstLettersUser() {
        if (user) {
            const name = user.sub['0']; //firstName
            const surname = user.sub['0']; //lastName
            return name + surname;
        }
    }

    return (
        <>
            <Button aria-describedby={id} variant="text" color={'secondary'} onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon/>}>
                <Avatar
                    sx={{
                        bgcolor: 'transparent',
                        border: '2px solid #000',
                        mr: {
                            xs: '0',
                            sm: 1
                        },
                        color: '#000',
                        width: '24px',
                        height: '24px',
                        lineHeight: '22px',
                        fontSize: '10px',
                        textTransform: 'uppercase'
                    }}
                >
                    {getFirstLettersUser()}
                </Avatar>
                <Typography
                    sx={{
                        fontSize: '14px',
                        color: '#000',
                        display: {
                            xs: 'none',
                            sm: 'inline-block'
                        }
                    }}
                >
                    {user ? user.firstName : ''} {user ? user.lastName : ''}
                </Typography>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    py: '0'
                }}
            >
                <List>
                    <ListItem onClick={handleOpenModal}>
                        <ListItemIcon
                            sx={{
                                minWidth: '32px',
                                color: '#000',
                                mr: 0.25
                            }}
                        >
                            <ExitToAppIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Esci" sx={{color: '#000'}} />
                    </ListItem>
                </List>
            </Popover>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Stack direction="row" alignItems="start" justifyContent='space-between'>
                        <Box>
                            <Typography variant={'p'}>
                                Uscire dall'applicazione?
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack direction="row" alignItems="start" justifyContent='end'>
                        <Button color="secondary" variant="text" onClick={handleCloseModal}>
                            Annulla
                        </Button>
                        <Button color="primary" variant="text" onClick={() => logoutUser()}>
                            Esci
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>

    );
}
