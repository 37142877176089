export const agGridItalianTranslation = {
    // Set Filter
    selectAll: '(Seleziona tutto)',
    selectAllSearchResults: '(Seleziona tutti i risultati di ricerca)',
    searchOoo: 'Cerca...',
    blanks: '(vuoto)',
    noMatches: 'Nessun risultato',

    // Number Filter & Text Filter
    filterOoo: 'Filtra...',
    equals: 'Uguale a',
    notEqual: 'Diverso da',
    blank: 'Nessuna riga',
    notBlank: 'Tutte le righe',
    empty: 'Scegli',

    // Number Filter
    lessThan: 'Minore di',
    greaterThan: 'Maggiore di',
    lessThanOrEqual: 'Minore di o uguale',
    greaterThanOrEqual: 'Maggiore di o uguale',
    inRange: 'In range',
    inRangeStart: 'da',
    inRangeEnd: 'a',

    // Text Filter
    contains: 'Contiene',
    notContains: 'Non Contiene',
    startsWith: 'Inizia con',
    endsWith: 'Termina con',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'E',
    orCondition: 'O',

    // Filter Buttons
    applyFilter: 'Applica',
    resetFilter: 'Resetta',
    clearFilter: 'Pulisci',
    cancelFilter: 'Cancella',

    // Filter Titles
    textFilter: 'Filtro testuale',
    numberFilter: 'Filtro numerico',
    dateFilter: 'Filtro data',
    setFilter: 'Filtro custom',

    // Group Column Filter
    groupFilterSelect: 'Seleziona campi:',

    // Side Bar
    columns: 'Colonne',
    filters: 'Filtri',

    // columns tool panel
    pivotMode: 'Modalità Pivot',
    groups: 'Gruppo di righe',
    rowGroupColumnsEmptyMessage: 'Trascina qui per settare un gruppo di righe',
    values: 'Valori',
    valueColumnsEmptyMessage: 'Trascina qui per aggregare',
    pivots: 'Etichette colonna',
    pivotColumnsEmptyMessage: 'Trascina quiper settare le etichette della colonna',

    // Header of the Default Group Column
    group: 'Gruppo',

    // Row Drag
    rowDragRow: 'riga',
    rowDragRows:'righe',

    // Other
    loadingOoo: 'Caricamento...',
    loadingError: 'Errore',
    noRowsToShow: 'Nessun dato da mostrare',
    enabled: 'Abilitato',

    // Menu
    pinColumn: 'Pin colonna',
    pinLeft: 'Pin a sinistra',
    pinRight: 'Pin a destra',
    noPin: 'Nessun Pin',
    valueAggregation: 'Valore aggregato',
    noAggregation: 'Nessuna aggregazione',
    autosizeThiscolumn: 'Autodimensiona questa colonna',
    autosizeAllColumns: 'Autodimensiona tutte le colonne',
    groupBy: 'Gruppa per',
    ungroupBy: 'Dividi per',
    resetColumns: 'Resetta colonne',
    expandAll: 'Espandi tutto',
    collapseAll: 'Chiudi tutto',
    copy: 'Copia',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copia con intestazione',
    copyWithGroupHeaders: 'Copia con gruppo di intestazioni',
    cut: 'Taglia',
    paste: 'Incolla',
    ctrlV: 'Ctrl+V',
    export: 'Esporta',
    csvExport: 'Esporta CSV ',
    excelExport: 'Esporta Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Somma',
    first: 'Primo',
    last: 'Ultimo',
    min: 'Minore',
    max: 'Maggiore',
    none: 'Nessuno',
    count: 'Conteggia',
    avg: 'Media',
    filteredRows: 'Filtrate',
    selectedRows: 'Selezionate',
    totalRows: 'Tutte le righe',
    totalAndFilteredRows: 'Righe',
    more: 'Di più',
    to: 'di',
    of: 'di',
    page: 'Pagina',
    nextPage: 'Pagina successiva',
    lastPage: 'Ultima pagina',
    firstPage: 'Prima pagina',
    previousPage: 'Pagina precedente',

    // Pivoting
    pivotColumnGroupTotals: 'Totale',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Grafico Pivot & Modalità Pivot',
    pivotChart: 'Grafico Pivot',
    chartRange: 'Grafico con range',

    columnChart: 'Colonna',
    groupedColumn: 'Gruppata',
    stackedColumn: 'Impilata',
    normalizedColumn: '100% Impilata',

    barChart: 'Barre',
    groupedBar: 'Gruppata',
    stackedBar: 'Impilata',
    normalizedBar: '100% Impialta',

    pieChart: 'Torta',
    pie: 'Torta',
    doughnut: 'Ciambella',

    line: 'Linea',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Istogramma',
    histogramFrequency: "Frequenza",

    combinationChart: 'Combina',
    columnLineCombo: 'Colonna & Linea',
    AreaColumnCombo: 'Area & Colonna',

    // Charts
    pivotChartTitle: 'Grafico Pivot',
    rangeChartTitle: 'Grafico con Range',
    settings: 'Impostazioni',
    data: 'Data',
    format: 'Formato',
    categories: 'Categorie',
    defaultCategory: '(Nessuna)',
    series: 'Serie',
    xyValues: 'Valori X Y',
    paired: 'Modalità comparazione',
    axis: 'Asse',
    navigator: 'Navigatrice',
    color: 'Colore',
    thickness: 'Spessore',
    xType: 'Tipo X',
    automatic: 'Automatico',
    category: 'Categoria',
    number: 'Numero',
    time: 'Tempo',
    autoRotate: 'Rotazione Automatica',
    xRotation: 'Rotazione X',
    yRotation: 'Rotazione Y',
    ticks: 'Ticks',
    width: 'Larghezza',
    height: 'Altezza',
    length: 'Lunghezza',
    padding: 'Margini',
    spacing: 'Spazio',
    chart: 'Grafico',
    title: 'Titolo',
    titlePlaceholder: 'Titolo del grafico - doppio click per editare',
    background: 'Sfondo',
    font: 'Font',
    top: 'Su',
    right: 'Destra',
    bottom: 'Giù',
    left: 'Sinistra',
    labels: 'Etichette',
    size: 'Grandezza',
    minSize: 'Grandezza Minima',
    maxSize: 'Grandezza Massima',
    legend: 'Legenda',
    position: 'Posizione',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Spazio Orizzontale',
    layoutVerticalSpacing: 'Spazio Verticale',
    strokeWidth: 'Larghezza Tratto',
    lineDash: 'Linea tratteggiata',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Marcatura',
    shadow: 'Ombra',
    blur: 'Sfocatura',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Larghezza della linea',
    normal: 'Normale',
    bold: 'Grassetto',
    italic: 'Corsivo',
    boldItalic: 'Grassetto corsivo',
    predefined: 'Predefinito',
    fillOpacity: 'Riempimento Opacità',
    strokeOpacity: 'Linea Opacità',
    histogramBinCount: 'Conteggio bin',
    columnGroup: 'Colonna',
    barGroup: 'Bar',
    pieGroup: 'Torta',
    lineGroup: 'Linea',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Istogramma',
    combinationGroup: 'Combinazione',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Torta',
    doughnutTooltip: 'Ciambella',
    lineTooltip: 'Linea',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Istogramma',
    columnLineComboTooltip: 'Column & Line',
    areaColumnComboTooltip: 'Area & Column',
    customComboTooltip: 'Combinazione personalizzata',
    noDataToChart: 'Nessun dato disponibile per comporre un grafico.',
    pivotChartRequiresPivotMode: 'Il grafico Pivot richiede che la modalità Pivot sia abilitata.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Collegamento alla griglia',
    chartUnlinkToolbarTooltip: 'Rimozione dalla griglia',
    chartDownloadToolbarTooltip: 'Scarica grafico',
    seriesChartType: 'Series Chart Type',
    seriesType: 'Series Type',
    secondaryAxis: 'Asse secondario',

    // ARIA
    ariaChecked: 'checked',
    ariaColumn: 'Colonna',
    ariaColumnGroup: 'Column Group',
    ariaColumnList: 'Column List',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaDefaultListName: 'List',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterInput: 'Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterToValue: 'Filter to value',
    ariaFilterValue: 'Filter Value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaHidden: 'hidden',
    ariaIndeterminate:'indeterminate',
    ariaInputEditor: 'Input Editor',
    ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaSearch: 'Search',
    ariaSortableColumn: 'Press ENTER to sort',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaUnchecked: 'unchecked',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Search filter values',

    // ARIA Labels for Drop Zones

    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'Values',
    ariaPivotDropZonePanelLabel: 'Column Labels',
    ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
    ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
    // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeperator}{column name}
    ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
    ariaDropZoneColumnComponentSortAscending: 'ascending',
    ariaDropZoneColumnComponentSortDescending: 'descending',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Menu Collona',
    ariaLabelCellEditor: 'Edita Cella',
    ariaLabelDialog: 'Modale',
    ariaLabelSelectField: 'Seleziona Campo',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Menu Contestuale',
    ariaLabelSubMenu: 'Sottomenù',
    ariaLabelAggregationFunction: 'Funzione di aggregazione',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.'

}