import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Step,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import CardDetailCart from "../card/CardDetail";
import CustomStepperLabel from "../components/Stepper";
import CardCart from "../card/CardCart";
import ApiService from "../services/ApiService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {getUser} from "../services/AuthService";
import SnackBarAlert from "../components/SnackBarAlert";

function getSteps() {
    return ['Informazioni', 'Prodotti', 'Conferma'];
}

export default function OrderCart() {
    const user = getUser();
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const edit = path.pathname.split('/')[3];

    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const [budgetSelected, setBudgetSelected] = useState(undefined);

    const [openModalStock, setOpenModalStock] = useState(false);
    const [alertBudget, setAlertBudget] = useState(false);
    const [productsNotAvailable, setProductsNotAvailable] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (edit === 'edit') {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        setActiveStep(2);

        ApiService.getOrderById(id).then(response => response.json()).then(async response => {
            if (response) {
                setOrder(response);
                if (response.budget?.amount === null) {
                    setBudgetSelected('Illimitato');
                } else {
                    setBudgetSelected((response.budget?.availableAmount / 100).toFixed(2) + ' CHF');
                }
            }

        });

    }, [id, edit])

    const steps = getSteps();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setOpenModalStock();
    };

    function handleCloseSnackBar(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    }

    function fetchStocks(item) {
        ApiService.getStockQuantityByProductId(item.product.id).then(response => response.json()).then(response => {
            if (response) {
                if (response.availableQuantity < item.quantity) {
                    setProductsNotAvailable(productsNotAvailable => [...productsNotAvailable, item.product]);
                }
            }
        })
    }

    async function submitOrder() {
        if (order.budget.availableAmount) {
            if (order.total > order.budget.availableAmount) {
                setAlertBudget(true);
            }
        }

        let responseSubmit = await ApiService.submitOrder(order.id);
        if (responseSubmit.status === 400) {
            setProductsNotAvailable([]);
            // eslint-disable-next-line array-callback-return
            order.items.map((item) => {
                fetchStocks(item);
            })
            setOpenModalStock(true);
        } else if (responseSubmit.status === 200) {
            if (responseSubmit) {
                const order = responseSubmit;
                setOrder(order);
                navigateToOrderDetails();
            }
        } else {
            navigateToOrderDetails();
        }
    }

    function navigateToOrderDetails() {
        let role = '';
        if (user) {
            role = user.ROLE[0].authority;
            if (role === 'MANAGER') {
                navigate('/order-details/' + order.id);
            }
            if (role === 'USER') {
                navigate('/order-user-details/' + order.id)
            }
            if (role === 'SECRETARIAT') {
                navigate('/order-user-details/' + order.id)
            }
        }
    }

    const alertDeleteItemFromOrder = (isDeleted) => {
        if (isDeleted) {
            setOpenSnackBar(true);
        }
    }

    const changeOrderInitial = (orderFinal) => {
        setOrder(orderFinal);
    }

    return (
        <>
            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <Breadcrumb/>
                    <Typography variant="h5" component="h2"
                                sx={{fontWeight: '500'}}>{isEdit ? 'Modifica ordine' : 'Nuovo Ordine'}</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                <Grid item xs={12} sm={6}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <CustomStepperLabel label={label}/>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                {order && order.budget ?
                    <Grid item xs={12} sm={4}>
                        <Box component={'div'} sx={{mb: 3, mt: 2}}>
                            <CardDetailCart show={true} budget={budgetSelected} total={order.totalFormatted}
                                            user={order.user}
                                            period={order.budget.schoolYear.name} location={order.deliveryLocation.name}
                                            budgetName={order.budget.name} notes={order.notes}
                                            alertBudget={alertBudget}/>
                        </Box>
                    </Grid>
                    :
                    ""
                }
            </Grid>

            <Box sx={{mt: 3}}>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'grid'
                        },
                        width: '100%',
                        columnGap: 1,
                        rowGap: 1,
                        p: 1.5,
                        gridTemplateColumns: {
                            xs: '1fr 1fr 1fr 1fr 1fr',
                            md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                        },
                        gridTemplateRows: {
                            xs: 'auto',
                            md: 'auto'
                        },
                        gridTemplateAreas: {
                            xs: `
                    'code code code availability availability'
                    'title title title title title'
                    'price input total total delete'
                    `,
                            md: `
                    'code title title title title availability price unit input total total delete' 
                    `,
                        },
                        borderBottom: '1px solid rgba(0, 0, 0, 0.46)'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'code',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Codice</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'availability',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Disponibilità</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'title',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Nome</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'price'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Costo</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'unit'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Des. unità</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'input',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Quantità</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'total',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Totale</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'delete'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        "& > div:nth-child(even)": {
                            background: '#ffffff',
                        }
                    }}
                >
                    {order && order.items?.length > 0 ?
                        order.items.map((item) =>
                            <CardCart item={item} orderInitial={order} changeOrderInitial={changeOrderInitial}
                                      alertDeleteItemFromOrder={alertDeleteItemFromOrder}/>
                        )
                        :
                        ""
                    }
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 1.5,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.46)'
                    }}
                >
                    <Typography fontWeight={'600'}>Totale: {order.totalFormatted} CHF</Typography>
                </Box>
            </Box>

            <Grid container
                  sx={{
                      mt: 3,
                      flexDirection: {
                          lg: 'row-reverse'
                      }
                  }}
            >
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={2} sx={{mb: 4}}>
                        <Grid item xs={6}>
                            <Button variant="outlined" color="primary"
                                    fullWidth
                                    onClick={() => {
                                        navigateToOrderDetails();
                                    }}>
                                Salva ordine
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" fullWidth
                                    disabled={order ? order.items?.length === 0 : false}
                                    onClick={() => {
                                        submitOrder();
                                    }}>
                                Inoltra ordine
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={6}>
                    <Button variant="flip"
                            onClick={() => {
                                navigate(-1);
                            }}>
                        Torna alla selezione prodotti
                    </Button>
                </Grid>
            </Grid>
            {openModalStock ?
                <Dialog
                    open={openModalStock}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ReportProblemOutlinedIcon sx={{mr: 1}}/> Attenzione
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography>I seguenti prodotti non sono più disponibili</Typography>
                            <TableContainer sx={{my: 2}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size='small'>Codice</TableCell>
                                            <TableCell size='small'>Nome</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productsNotAvailable.length > 0 ?
                                            productsNotAvailable.map((product) => (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" sx={{verticalAlign: 'top'}}>
                                                        {product.code}
                                                    </TableCell>
                                                    <TableCell>{product.name}</TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography align={'center'} sx={{fontWeight: '600'}}>I prodotti saranno nuovamente
                                disponibili entro due settimane</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} variant={'contained'} color={'primary'}>
                            Ok, ho capito
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                ''}

            <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'success'}
                           title={'Fatto!'}
                           duration={2000}
                           body={'L\'articolo è stato rimosso correttamente dal tuo ordine.'}/>
        </>

    );
}