import {AgGridReact} from "ag-grid-react";
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-material.min.css';
import '../assets/css/agGrid/custom-table-agGrid.css';
import '../configuration/agGridItalianTranslation';
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";

export default function AgGrid({children}) {

    const onGridReady = params => {
        const allColumnIds = [];
        params.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeColumns(allColumnIds, false);
    };

    return (
        <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
            <div style={{width: '100%', height: '100%'}}>
                <AgGridReact
                    onGridReady={onGridReady}
                    columnDefs={children.columnDefs}
                    defaultColDef={children.defaultColDef}
                    rowData={children.rowData}
                    pagination={true}
                    paginationPageSize={25}
                    singleClickEdit={true}
                    domLayout={'autoHeight'}
                    localeText={agGridItalianTranslation}
                    animateRows={true}
                >
                </AgGridReact>
            </div>
        </div>
    );
}

