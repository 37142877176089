import {Box, Button, Grid, Paper, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import CardCartOrderReturn from "../card/CardCartOrderReturn";

export default function OrderReturn() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [order, setOrder] = useState(false);
    const [listOfItemsToReturn, setListOfItemsToReturn] = useState(new Map());

    useEffect(() => {
        ApiService.getOrderById(id).then(response => response.json()).then(response => {
            setOrder(response);
        });

    }, [id]);

    const updateListOfItemsToReturn = (item) => {
        setListOfItemsToReturn(itemToReturn => new Map(itemToReturn.set(item.orderItemId, item.quantityToReturn)));
    }

    function partialReturnSingleItemOrder() {
        listOfItemsToReturn.forEach(function (value, key) {
            const body = {
                orderId: order.id,
                orderItemId: key,
                quantityToReturn: value
            }

            ApiService.partialReturnSingleItemOrder(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate('/order-details/' + order.id)
                }
            });
        })
    }

    function partialReturnSubmittedSingleItemOrder() {
        listOfItemsToReturn.forEach(function (value, key) {
            const body = {
                orderId: order.id,
                orderItemId: key,
                quantityToDelete: value
            }

            ApiService.partialReturnSubmittedSingleItemOrder(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate('/order-details/' + order.id)
                }
            });
        })
    }
    return (
        <>
            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <Breadcrumb/>
                    <Typography variant="h5" component="h2"
                                sx={{fontWeight: '500'}}>{order?.status === 'SUBMITTED' ? 'Modifica Ordine' : 'Reso Ordine'}</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}} columnSpacing={3}>
                <Grid item xs={12} md={7} lg={6}>
                    <Paper
                        component={'div'}
                        elevation={0}
                        sx={{
                            borderRadius: 0,
                            borderTop: '2px solid #c23052',
                            p: 2,
                            mb: 4
                        }}
                    >
                        <Grid container spacing={2} sx={{width: '100%'}}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1.25}>
                                    <Typography><Typography component={'span'}
                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Utente: </Typography> {order?.user?.fullName}
                                    </Typography>
                                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Creato
                                        da:</Typography> {order?.createdBy}</Typography>
                                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Status
                                        dell'ordine:</Typography> {order?.statusLabel}
                                    </Typography>
                                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>ID
                                        Ordine:</Typography> {order?.id}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1.25}>
                                    <Typography><Typography component={'span'}
                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Funzione:</Typography> {order?.budget?.name}
                                    </Typography>
                                    <Typography>
                                        <Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Periodo:</Typography> {order?.budget?.schoolYear?.name}
                                    </Typography>
                                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Totale
                                        (CHF):</Typography> {order?.totalFormatted}</Typography>
                                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Sede
                                        fornitura:</Typography> {order?.deliveryLocation?.name}
                                    </Typography>
                                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data
                                        creazione:</Typography> {order?.createdAtFormatted}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Box sx={{mt: 3}}>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'grid'
                        },
                        width: '100%',
                        columnGap: 1,
                        rowGap: 1,
                        p: 1.5,
                        gridTemplateColumns: {
                            xs: '1fr 1fr 1fr 1fr 1fr 2',
                            md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                        },
                        gridTemplateRows: {
                            xs: 'auto',
                            md: 'auto'
                        },
                        gridTemplateAreas: {
                            xs: `
                    'code code code availability availability'
                    'title title title title title'
                    'price qnt input'
                    `,
                            md: `
                    'code title title title title availability price unit qnt input' 
                    `,
                        },
                        borderBottom: '1px solid rgba(0, 0, 0, 0.46)'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'code',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Codice prodotto</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'title',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Nome prodotto</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'price'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Costo</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'unit'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Des. unità</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'qnt',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Quantità inoltrata</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'input',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Quantità da rimuovere</Typography>
                    </Box>

                </Box>
                <Box
                    sx={{
                        "& > div:nth-child(even)": {
                            background: '#ffffff',
                        }
                    }}
                >
                    {order && order.items.length > 0 ?
                        order.items.map((item) =>
                            <CardCartOrderReturn order={order} item={item}
                                                 updateListOfItemsToReturn={updateListOfItemsToReturn}/>
                        )
                        :
                        ""
                    }
                </Box>
            </Box>

            <Grid container
                  sx={{
                      mt: 3,
                      flexDirection: {
                          lg: 'row-reverse'
                      }
                  }}
            >
                <Grid item xs={12} lg={6}>
                    <Grid container spacing={2} sx={{mb: 4}}>
                        <Grid item xs={6}>
                        </Grid>
                        <Grid item xs={6}>
                            {order?.status === 'SUBMITTED' ?
                                <Button variant="contained" color="primary" fullWidth
                                        disabled={listOfItemsToReturn.size === 0}
                                        onClick={() => {
                                            partialReturnSubmittedSingleItemOrder();
                                        }}>
                                    Salva
                                </Button>
                                :
                                <Button variant="contained" color="primary" fullWidth
                                        disabled={listOfItemsToReturn.size === 0}
                                        onClick={() => {
                                            partialReturnSingleItemOrder();
                                        }}>
                                    Rendi articoli
                                </Button>
                            }

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={6}>
                    <Button variant="flip"
                            onClick={() => {
                                navigate(-1);
                            }}>
                        Torna al dettaglio ordine
                    </Button>
                </Grid>
            </Grid>
        </>

    );
}