import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import '../assets/css/mediaPrint/print.css';
import Breadcrumb from "../components/Breadcrumb";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses, TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import 'ag-grid-enterprise';
import {styled} from "@mui/material/styles";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'rgba(0,0,0,.54)',
        fontSize: '10px',
        paddingBottom: '8px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '12px',
        fontWeight: '400',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0,0,0,.01)',
    },
}));

const data = {
    columnDefs: [
        {
            headerName: "Codice prodotto",
            field: "product.code",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            maxWidth: 200,
        },
        {
            headerName: "Nome prodotto",
            field: "product.name",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 500,
        },
        {
            headerName: "Quantità",
            field: "quantity",
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        },
        {
            headerName: "Costo unitario (CHF)",
            field: "product.priceFormatted",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function OrderDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [order, setOrder] = useState([]);
    const [commentReturn, setCommentReturn] = useState('');

    const [inProgress, setInProgress] = useState(true);
    const [loading, setLoading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openDialogAbortOrder, setOpenDialogAbortOrder] = useState(false);
    const [openCommentDialog, setOpenCommentDialog] = useState(false);

    const gridRef = useRef();

    useEffect(() => {
        setLoading(true);
        ApiService.getOrderById(id).then(response => response.json()).then(response => {
            setLoading(false);
            const order = response;
            if (order.status !== 'IN_PROGRESS') {
                setInProgress(false);
            }

            data.rowData = order.items;
            setOrder(order);
        })
    }, [id]);

    const confirmDelete = () => {
        setOpenDialog(true);
    };

    const confirmAbort = () => {
        setOpenDialogAbortOrder(true);
    };

    const openCommentReturn = () => {
        setOpenCommentDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseAbortOrder = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialogAbortOrder(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    const handleCloseCommentDialog = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenCommentDialog(false);
    };

    const onBtExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            processCellCallback: (params) => {
                const colDef = params.column.getColDef()
                if (colDef.valueFormatter) {
                    const valueFormatterParams: ValueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: true,
                        colDef: params.column.getColDef()
                    };
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            },
        });
    }, []);

    function deleteOrder() {
        ApiService.deleteOrderByOrderId(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate(-1);
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function processOrder() {
        ApiService.processOrder(id).then(response => response.json()).then(response => {
            if (response) {
                setOrder(response);
            } else {
                setOpenSnackBar(true);
            }
        })
    }

    function abortOrder() {
        ApiService.abortOrder(id).then(response => response.json()).then(response => {
            if (response) {
                navigate('/');
            } else {
                setOpenSnackBar(true);
            }
        })
    }

    function cancelOrder() {
        ApiService.cancelOrder(id).then(response => response.json()).then(response => {
            if (response) {
                setOrder(response);
            } else {
                setOpenSnackBar(true);
            }
        })
    }

    function returnOrder() {
        const body = {
            orderId: id,
            comment: commentReturn
        }
        ApiService.returnOrder(body).then(response => response.json()).then(response => {
            if (response) {
                setOpenCommentDialog(false);
                setOrder(response);
                navigate('/');
            } else {
                setOpenSnackBar(true);
            }
        })
    }

    function approveOrder() {
        ApiService.approveOrder(id).then(response => response.json()).then(response => {
            if (response) {
                setOrder(response);
            } else {
                setOpenSnackBar(true);
            }
        })
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Ordine</Typography>
            </Grid>
        </Grid>


        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={7} lg={6}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Grid container spacing={2} sx={{width: '100%'}}>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1.25}>
                                <Typography><Typography component={'span'}
                                                        sx={{color: 'rgba(0,0,0,.7)'}}>Utente: </Typography> {order?.user?.fullName}
                                </Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Creato
                                    da:</Typography> {order?.createdBy}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Stato
                                    ordine:</Typography> {order?.statusLabel}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>ID
                                    Ordine:</Typography> {order?.id}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack spacing={1.25}>
                                <Typography><Typography component={'span'}
                                                        sx={{color: 'rgba(0,0,0,.7)'}}>Funzione:</Typography> {order?.budget?.name}
                                </Typography>
                                <Typography>
                                    <Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Periodo:</Typography> {order?.budget?.schoolYear?.name}
                                </Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Totale
                                    (CHF):</Typography> {order?.totalFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Sede
                                    fornitura:</Typography> {order?.deliveryLocation?.name}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data
                                    creazione:</Typography> {order?.createdAt}</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            {order.status !== 'ABORTED' ?
                <Grid item xs={12} md={2}>
                    <Stack spacing={1.25} sx={{mb: 1.25}}>
                        {order.status === 'SUBMITTED' ?
                            <Button variant="contained" fullWidth color="primary"
                                    onClick={() => approveOrder()}>
                                Approva
                            </Button>
                            :
                            ''
                        }

                        {
                            order.status === 'PROCESSED' ?
                                <Button variant="contained" fullWidth color="primary" disabled={order.items?.length === 0}
                                        onClick={() => navigate('/order-return/' + id)}>
                                    Reso
                                </Button>
                                :
                                ''
                        }

                        {
                            order.status === 'APPROVED' ?
                                <Button variant="contained" fullWidth color="primary"
                                        onClick={() => processOrder()}>
                                    Evadi
                                </Button>
                                :
                                ''
                        }


                        {order.status === 'APPROVED' ?
                            <Button variant="contained" fullWidth color="primary"
                                    onClick={() => cancelOrder()}>
                                Storno
                            </Button>
                            :
                            ''
                        }

                        {inProgress ?
                            <Button variant="contained" fullWidth color="primary"
                                    onClick={() => navigate('/order-info/' + id)}>
                                Modifica
                            </Button>
                            :
                            ''
                        }

                        {order.status === 'SUBMITTED' ?
                            <Button variant="contained" fullWidth color="secondary"
                                    onClick={() => openCommentReturn()}>
                                Ritorna
                            </Button>
                            :
                            ''
                        }

                        {inProgress ?
                            <Button variant="contained" fullWidth color="secondary"
                                    onClick={() => confirmDelete()}>
                                Elimina
                            </Button>
                            :
                            ''
                        }

                        {order.status === 'ABORTED' || order.status === 'IN_PROGRESS' || order.status === 'PROCESSED' ?
                            ''
                            :
                            <Button variant="contained" fullWidth color="secondary"
                                    onClick={() => confirmAbort()}>
                                Annulla
                            </Button>
                        }
                    </Stack>

                </Grid>
                :
                ''}
            <Grid item xs={12} md={2}>
                <Stack spacing={1.25}>
                    <Button variant="flip" fullWidth startIcon={<FileDownloadOutlinedIcon/>} onClick={onBtExport}>
                        Excel
                    </Button>
                    <Button variant="flip" fullWidth color="primary" startIcon={<LocalPrintshopOutlinedIcon/>}
                            onClick={() => window.print()}>
                        Stampa
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        {loading ?
            ''
            :
            <Grid container sx={{mt: 3}} id={"print-none-grid"}>
                <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                    <div style={{width: '100%', height: '100%'}}>
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={data.defaultColDef}
                            columnDefs={data.columnDefs}
                            rowData={data.rowData}
                            pagination={true}
                            paginationPageSize={25}
                            domLayout={'autoHeight'}
                            localeText={agGridItalianTranslation}
                            animateRows={true}
                        >
                        </AgGridReact>
                    </div>
                </div>
            </Grid>}

        <Grid container sx={{mt: 3}} id={'print-grid'}>
            <Table sx={{minWidth: 700}} aria-label="customized table" size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell width='15%'>Codice prodotto</StyledTableCell>
                        <StyledTableCell>Nome prodotto</StyledTableCell>
                        <StyledTableCell width='20%'>Costo unitario (CHF)</StyledTableCell>
                        <StyledTableCell width='20%'>Quantità</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {order && order.items && order.items.length > 0 ?
                        order.items.map((item) => (
                            <StyledTableRow>
                                <StyledTableCell>{item.product.code}</StyledTableCell>
                                <StyledTableCell>{item.product.name}</StyledTableCell>
                                <StyledTableCell>{item.quantity}</StyledTableCell>
                                <StyledTableCell>{item.price / 100}</StyledTableCell>
                            </StyledTableRow>
                        ))
                        :
                        ''}
                </TableBody>
                <TableFooter>
                    {order && order.total ?
                        <>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell sx={{textAlign: 'right', color: '#000000de'}}>Totale:</StyledTableCell>
                            <StyledTableCell sx={{color: '#000000de'}}> {order.totalFormatted} (CHF)</StyledTableCell>
                        </>
                        :
                        ''
                    }
                    </TableFooter>
            </Table>
        </Grid>

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip" onClick={() => {
                    navigate(-1)
                }}>
                    Indietro
                </Button>
            </Grid>

        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteOrder} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <BasicDialog isOpen={openDialogAbortOrder} handleClose={handleCloseAbortOrder} deleteFunction={abortOrder}
                     title={'Attenzione!'}
                     body={'Sei sicuro di voler annullare quest\'ordine? L\'azione non è reversibile.'}
                     closeLabel={'No'} successLabel={'Sì, annulla'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'error'}
                       title={'Errore!'}
                       duration={10000}
                       body={'Qualcosa è andato storto, riprova più tardi.'}/>

        <Dialog
            open={openCommentDialog}
            onClose={handleCloseCommentDialog}
            aria-labelledby="openSnackBar-dialog-title"
            aria-describedby="openSnackBar-dialog-description"
            maxWidth='lg'
        >
            <DialogTitle id="openSnackBar-dialog-title">
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <HelpOutlineIcon sx={{mr: 1}}/> Messaggio
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>Inserisci il messaggio per l'Utente</DialogContentText>
                <TextField
                    id="comment"
                    label="Messaggio"
                    variant="standard"
                    fullWidth
                    sx={{
                        minWidth: {
                            lg: '640px'
                        }
                    }}
                    onChange={(e) => setCommentReturn(e.target.value)}
                    multiline
                    rows={5}
                    autoFocus
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseCommentDialog} variant={'outlined'} color={'secondary'}>Annulla</Button>
                <Button onClick={() => returnOrder()} variant={'contained'} color={'primary'}>
                    Invia
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}