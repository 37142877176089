import React from 'react';
import logo from "../assets/img/lvga-logo.svg";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();

    return (<>

            <Grid container sx={{height: '100vh'}}>
                <Grid item xs={12} md={3}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            background: '#212121',
                            justifyContent: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                px: 2,
                                py: 4,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start'
                            }}
                        >
                            <img src={logo} alt="lvga logo" width="68" height="auto"/>
                            <Typography sx={{
                                fontSize: '52px',
                                fontWeight: '500',
                                marginLeft: '15px',
                                color: '#ffffff'
                            }}>RiMa</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                p: 5
                            }}
                        >
                            <Typography variant={'h2'} sx={{mb: 1.5}}>Pagina non trovata</Typography>
                            <Button variant={'contained'} color="primary" size={'large'}
                                    onClick={() => {
                                        navigate('/')
                                    }}>
                                Vai alla HOMEPAGE
                            </Button>


                        </Box>
                    </Box>

                </Grid>
            </Grid>
        </>

    )
}