import {Button, Grid, Paper, Stack} from "@mui/material";
import React, {useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import AgGrid from "../components/AgGrid";
import Breadcrumb from "../components/Breadcrumb";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ApiService from "../services/ApiService";
import {statusFormatter} from "../configuration/agGridFormatterFunctions";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        hidden={value !== index}
        id={`wrapped-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Grid container sx={{mt: 2}}>
                <Grid xs={12}>
                    {children}
                </Grid>
            </Grid>
        )}
    </div>);
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`, 'aria-controls': `wrapped-Orders-${index}`,
    };
}

const dataOrders = {
    columnDefs: [
        {
            headerName: "Creato da",
            field: "user.fullName",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Periodo",
            field: "budget.schoolYear.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Totale (CHF)",
            field: "totalFormatted",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Sede fornitura",
            field: "budget.location?.name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data creazione",
            field: "createdAtFormatted",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Stato ordine",
            field: "statusLabel",
            cellRenderer: statusFormatter,
            minWidth: 200,
            sortable: true
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'} to={'/order-details/' + params.data.id}>
                                Dettaglio
                            </Link>
                        </Grid>
                        {params.data.status !== 'IN_PROGRESS' ?
                            ''
                            :
                            <Grid item>
                                <Link
                                    className={'muiBtn muiBtnSecondary'}
                                    to={'/order-info/' + params.data.id}
                                >
                                    Modifica
                                </Link>
                            </Grid>
                        }

                    </Grid>
                )
            },
            suppressSizeToFit: true,
            width: 200
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

const dataFunctions = {
    columnDefs: [
        {
            headerName: "Nome funzione", field: "name", minWidth: 200, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget attuale (CHF)",
            field: "availableAmountFormatted",
            minWidth: 100,
            sortable: true, filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget iniziale (CHF)",
            field: "amountFormatted",
            minWidth: 100,
            sortable: true, filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Budget aggiuntivo (CHF)",
            field: "arbitraryAmountFormatted",
            minWidth: 200,
            sortable: true, filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SI", field: "primarySchoolStudents", minWidth: 120,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 1° ciclo", field: "elementarySchoolFirstCycleStudents", minWidth: 120,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 2° ciclo", field: "elementarySchoolSecondCycleStudents", minWidth: 120,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'} to={'/function-details/' + params.data.id}>
                                Dettaglio
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/edit-function/' + params.data.id} className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function UserDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = Number(path.pathname.split('/')[2]);

    const [value, setValue] = React.useState('one');
    const [loading, setLoading] = React.useState(true);

    const [currentUser, setCurrentUser] = React.useState(undefined);

    useEffect(() => {
        setLoading(true);
        ApiService.getUsers().then(response => response.json()).then(response => {
            const currentUser = response.find((user) => user.id === id);
            setCurrentUser(currentUser);

            ApiService.getOrdersByUserId(currentUser.id).then(response => response.json()).then(response => {
                setLoading(false);
                const orders = response;
                if (orders.orders.length > 0) {
                    dataOrders.rowData = orders.orders;
                }
            });

            ApiService.getBudgetsByUserId(currentUser.id).then(response => response.json()).then(response => {
                const budgets = response;
                if (budgets.budgets.length > 0) {
                    dataFunctions.rowData = budgets.budgets;
                }
            });

        });
    }, [id]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <Breadcrumb/>
                    <Grid container>
                        <Grid xs={12} md={6}>
                            <Typography variant="h5" component="h2"
                                        sx={{fontWeight: '500'}}>Utente {currentUser?.firstName} {currentUser?.lastName}</Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}} columnSpacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper
                        component={'div'}
                        elevation={0}
                        sx={{
                            borderRadius: 0,
                            borderTop: '2px solid #c23052',
                            p: 2,
                            mb: 4
                        }}
                    >
                        <Stack spacing={1.25}>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Nome: </Typography> {currentUser?.firstName}
                            </Typography>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Cognome:</Typography> {currentUser?.lastName}
                            </Typography>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Email:</Typography> {currentUser?.email}
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Stack spacing={1.25}>
                        <Button variant="contained" color="primary" fullWidth disabled>
                            Modifica
                        </Button>
                        <Button variant="flip" fullWidth disabled>
                            Disattiva
                        </Button>
                        <Button variant="outlined" color="secondary" fullWidth disabled>
                            Elimina
                        </Button>
                    </Stack>
                </Grid>
            </Grid>


            <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: 3}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab value="one"
                         label="Ordinazioni"
                         {...a11yProps('one')}
                    />
                    <Tab value="two" label="Funzioni" {...a11yProps('two')} />
                </Tabs>
            </Box>

            <TabPanel value={value} index="one">
                {!loading ?
                    <AgGrid children={dataOrders}/>
                    :
                    ''
                }

            </TabPanel>
            <TabPanel value={value} index="two">
                <Grid container>
                    <Grid xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2
                            }}
                        >
                            <Button variant="contained" color="primary" onClick={() => navigate('/edit-function')}>
                                Aggiungi funzione
                            </Button>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        {!loading ?
                            <AgGrid children={dataFunctions}/>
                            :
                            ''
                        }
                    </Grid>
                </Grid>
            </TabPanel>


            <Grid container sx={{mt: 5}}>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary"
                            style={{backgroundColor: 'white', color: 'black'}} onClick={() => {
                        navigate('/users')
                    }}>
                        Indietro
                    </Button>
                </Grid>

            </Grid>
        </>
    );
}