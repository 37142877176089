import {Navigate, Route, Routes} from "react-router-dom";
import Login from "../pages/Login"
import Orders from "../components/Orders"
import Home from "../pages/Home"
import NotFound from "../pages/NotFound"
import OrderInfo from "../pages/OrderInfo";
import OrderProducts from "../pages/OrderProducts";
import OrderDetails from "../pages/OrderDetails";
import OrderCart from "../pages/OrderCart";
import Users from "../components/Users"
import CostLimit from "../components/CostLimit"
import Locations from "../components/Locations"
import UserDetails from "../pages/UserDetails"
import EditLocation from "../pages/EditLocation";
import Functions from "../components/Functions"
import Coupons from "../components/Coupons";
import Catalogs from "../components/Catalogs";
import Categories from "../components/Categories";
import Logs from "../components/Logs";
import Periods from "../components/Periods";
import EditProduct from "../pages/EditProduct";
import EditCatalog from "../pages/EditCatalog";
import Products from "../components/Products";
import EditFunction from "../pages/EditFunction";
import EditPeriod from "../pages/EditPeriod";
import EditCategory from "../pages/EditCategory";
import CatalogDetails from "../pages/CatalogDetails";
import ProductDetails from "../pages/ProductDetails";
import PeriodDetails from "../pages/PeriodDetails";
import LocationDetails from "../pages/LocationDetails";
import CategoryDetails from "../pages/CategoryDetails";
import FunctionDetails from "../pages/FunctionDetails";
import EditStock from "../pages/EditStock";
import {isAuthenticated} from "../services/AuthService";
import HomeUser from "../pages/HomeUser";
import HomeSecretary from "../pages/HomeSecretary";
import CouponDetails from "../pages/CouponDetails";
import EditCoupon from "../pages/EditCoupon";
import OrderUserDetails from "../pages/OrderUserDetails";
import OrderReturn from "../pages/OrderReturn";
import ManagerManual from "../pages/ManagerManual";
import UserManual from "../pages/UserManual";

function Routing() {
    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            <Route path="/" element={isAuthenticated() ? <Home/> : <Navigate to="/login"/>}>
                <Route path={'/'} element={isAuthenticated() ? <Orders/> : <Navigate to="/login"/>}/>

                <Route path="/" element={<Orders/>}/>
                <Route path="order-info" element={<OrderInfo/>}/>
                <Route path="order-info/:id" element={<OrderInfo/>}/>
                <Route path="order-products" element={<OrderProducts/>}/>
                <Route path="order-products/:id" element={<OrderProducts/>}/>
                <Route path="order-products/:id/edit" element={<OrderProducts/>}/>
                <Route path="order-details" element={<OrderDetails/>}/>
                <Route path="order-details/:id" element={<OrderDetails/>}/>
                <Route path="order-cart" element={<OrderCart/>}/>
                <Route path="order-cart/:id" element={<OrderCart/>}/>
                <Route path="order-cart/:id/edit" element={<OrderCart/>}/>

                <Route path="order-return/:id/" element={<OrderReturn/>}/>

                <Route path="home-user" element={<HomeUser/>}/>
                <Route path="order-user-details/:id" element={<OrderUserDetails/>}/>

                <Route path="home-secretary" element={<HomeSecretary/>}/>

                <Route path="periods" element={<Periods/>}/>
                <Route path="edit-period" element={<EditPeriod/>}/>
                <Route path="edit-period/:id" element={<EditPeriod/>}/>
                <Route path="period-details/:id" element={<PeriodDetails/>}/>

                <Route path="locations" element={<Locations/>}/>
                <Route path="edit-location" element={<EditLocation/>}/>
                <Route path="edit-location/:id" element={<EditLocation/>}/>
                <Route path="location-details/:id" element={<LocationDetails/>}/>

                <Route path="functions" element={isAuthenticated() ? <Functions/> : <Navigate to="/login"/>}/>
                <Route path="edit-function" element={<EditFunction/>}/>
                <Route path="edit-function/:id" element={<EditFunction/>}/>
                <Route path="function-details/:id" element={<FunctionDetails/>}/>

                <Route path="catalogs" element={<Catalogs/>}/>
                <Route path="edit-catalog" element={<EditCatalog/>}/>
                <Route path="edit-catalog/:id" element={<EditCatalog/>}/>
                <Route path="catalog-details/:id" element={<CatalogDetails/>}/>

                <Route path="categories" element={<Categories/>}/>
                <Route path="edit-category" element={<EditCategory/>}/>
                <Route path="edit-category/:id" element={<EditCategory/>}/>
                <Route path="category-details/:id" element={<CategoryDetails/>}/>

                <Route path="products" element={<Products/>}/>
                <Route path="edit-product" element={<EditProduct/>}/>
                <Route path="edit-product/:id" element={<EditProduct/>}/>
                <Route path="product-details/:id" element={<ProductDetails/>}/>

                <Route path="logs" element={<Logs/>}/>

                <Route path="cost-limit" element={<CostLimit/>}/>

                <Route path="users" element={<Users/>}/>
                <Route path="user-details" element={<UserDetails/>}/>
                <Route path="user-details/:id" element={<UserDetails/>}/>

                <Route path="coupons" element={<Coupons/>}/>
                <Route path="coupon-details/:id" element={<CouponDetails/>}/>
                <Route path="edit-coupon" element={<EditCoupon/>}/>
                <Route path="edit-coupon/:id" element={<EditCoupon/>}/>

                <Route path="edit-stock" element={<EditStock/>}/>
                <Route path="edit-stock/:id" element={<EditStock/>}/>
                <Route path="edit-stock/:id/new" element={<EditStock/>}/>
                <Route path="edit-stock/:id/:id" element={<EditStock/>}/>

                <Route path="manager-manual" element={<ManagerManual/>}/>
                <Route path="user-manual" element={<UserManual/>}/>
            </Route>

            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default Routing;