import React from 'react';
import {Breadcrumbs} from "@mui/material";


export default function Breadcrumb() {
    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{mb: 1, fontSize: '0.875rem'}}>
        </Breadcrumbs>
    )
}


