import React from "react";

export function statusFormatter(params) {
    const status = params.value;
    let statusFormatted;
    switch (status) {
        case 'In stesura':
            statusFormatted = <span>{status}</span>;
            break;
        case 'Inoltrato':
            statusFormatted = <span style={{color: '#FF9800'}}>{status}</span>;
            break;
        case 'Approvato':
            statusFormatted = <span style={{color: '#4CAF50'}}>{status}</span>;
            break;
        case 'Evaso':
            statusFormatted = <span style={{color: '#4CAF50'}}>{status}</span>;
            break;
        case 'Annullato':
            statusFormatted = <span style={{color: '#f44336'}}>{status}</span>;
            break;
        case 'Reso':
            statusFormatted = <span style={{color: '#f44336'}}>{status}</span>;
            break;
        default:
            console.log('Errore nel caricamento dello status ordine')
    }
    return statusFormatted;
}

export function colorFormatter(params) {
    const colorFormatted = <span style={{color: '#f44336'}}>{params.value}</span>;
    return colorFormatted;
}

export function enabledFormatter(params) {
    const enabled = params.value;
    let enabledFormatted = 'No';
    if (!enabled) {
        enabledFormatted = 'Si';
    }
    return enabledFormatted;
}
