import * as React from 'react';
import {Button, Grid, SvgIcon, Box, Typography} from "@mui/material";
import {ContactSupportOutlined} from "@mui/icons-material";

function Logo(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 68 83" fill="none">
            <g clip-path="url(#clip0_106_21093)">
                <path d="M29.2202 77.3241C15.4071 74.9325 5.04693 62.9747 5.04693 48.6263V41.4517H0V48.6263C0 65.6326 12.75 79.981 29.2202 82.3726V77.3241Z" fill="#333333"/>
                <path d="M38.7825 77.3241C52.5957 74.9326 62.9549 62.9757 62.9549 48.6273V41.4526H67.7365V48.6273C67.7365 65.6336 54.9865 79.982 38.5163 82.3736V77.325H38.7825V77.3241Z" fill="#333333"/>
                <path d="M29.2202 0H0V31.6211H4.78159V4.78311H29.2202V0Z" fill="#333333"/>
                <path d="M38.7825 0V4.78311H63.2202V31.6211H68.0018V0H38.7825Z" fill="#333333"/>
                <path d="M28.4233 27.6351H19.657V14.6147H15.1408V31.6211H28.4233V27.6351Z" fill="#333333"/>
                <path d="M48.8764 31.6211L55.5172 14.6147H50.4702L46.7518 26.0408L43.0325 14.6147H38.2509L44.6264 31.6211H48.8764Z" fill="#333333"/>
                <path d="M21.7816 51.8153H24.9693V54.4723C24.7031 54.4723 24.4386 54.7387 23.9061 54.7387C23.3755 54.7387 23.1092 54.7387 22.5785 54.7387C21.7816 54.7387 20.9847 54.7387 20.454 54.4723C19.9233 54.206 19.3908 53.9415 18.8601 53.4088C18.3294 52.878 18.0632 52.3453 18.0632 51.8144C17.7969 51.2836 17.7969 50.4864 17.7969 49.6892C17.7969 48.892 17.7969 48.3612 18.0632 47.8285C18.3294 47.2977 18.5939 46.765 19.1264 46.2341C19.657 45.7033 19.9233 45.437 20.7202 45.1706C21.2509 44.9043 21.7834 44.9043 22.5803 44.9043C23.3773 44.9043 24.1742 45.1706 24.7049 45.4351C25.2356 45.7015 25.7681 45.966 26.0325 46.4986L26.2988 46.765L29.2202 43.5762L28.954 43.3099C28.157 42.5127 27.3601 41.9819 26.0325 41.7155C24.9693 41.1865 23.6417 41.1865 22.3141 41.1865C20.9865 41.1865 19.9233 41.4529 18.5957 41.7174C17.7988 41.9837 16.7356 42.5146 15.9396 43.3117C15.1435 44.1089 14.3457 44.9061 14.0794 46.2341C13.5488 47.2977 13.2825 48.3594 13.2825 49.6883C13.2825 51.0163 13.5488 52.3453 14.0794 53.4079C14.6101 54.4714 15.1426 55.5331 15.9396 56.3303C16.7365 57.1275 17.7997 57.6583 18.861 58.191C19.9242 58.4573 21.2518 58.7218 22.5794 58.7218C23.907 58.7218 24.9702 58.4555 26.2978 58.191C27.361 57.9247 28.4224 57.6601 29.2193 57.1275V48.0939H21.7816V51.8153Z" fill="#333333"/>
                <path d="M48.611 51.5487H44.8926L46.7528 46.2348L48.611 51.5487ZM48.8764 41.4517H44.6264L37.7202 58.4571H42.5018L43.8294 55.2683H49.9387L51.2663 58.4571H56.3132L48.8764 41.4517Z" fill="#333333"/>
            </g>
            <defs>
                <clipPath id="clip0_106_21093">
                    <rect width="68" height="82.3727" fill="white"/>
                </clipPath>
            </defs>
        </SvgIcon>
    );
}

function BottomBar() {

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                p: '0.25rem 1rem',
                flex: 'auto',
                background: 'whitesmoke'
            }}
        >
            <Grid item>
                <Box sx={{display:'flex', alignItems: 'center'}}>
                    <Logo sx={{mr: 1}} size={'small'} />
                    <Typography fontSize={'small'} sx={{ fontSize: '12px', color: '#000000de'}}>©2023 Città di Lugano</Typography>
                </Box>
            </Grid>
            <Grid item>
                <Button onClick={() => window.location = 'mailto:helpdesk@lugano.ch'} variant="text" color='secondary' size="small" sx={{mx: 1.5, textTransform: 'initial', fontSize: '12px', color: '#000000de'}}
                        startIcon={<ContactSupportOutlined/>}>
                    <span>Contatta il supporto</span>
                </Button>
            </Grid>
        </Grid>
    );
}

export default BottomBar;