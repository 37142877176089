import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@mui/material";
import AgGrid from "./AgGrid";
import {Link} from "react-router-dom";
import ApiService from "../services/ApiService";

const data = {
    columnDefs: [
        {
            headerName: "ID Utente", field: "id", sortable: true, minWidth: 100,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Nome", field: "firstName", sortable: true, minWidth: 180,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Cognome", field: "lastName", minWidth: 180, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Email", field: "email", sortable: true, minWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'} to={'/user-details/' + params.data.id}>
                                Visualizza
                            </Link>
                        </Grid>
                    </Grid>
                )
            },

        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function Users() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        ApiService.getUsers().then(response => response.json()).then(response => {
            setLoading(false);
            const users = response;
            if (users.length > 0) {
                data.rowData = users;
            }
        });
    }, []);

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12} sm={10}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Utenti</Typography>
            </Grid>
        </Grid>
        <Grid container sx={{my: 3}}>
            {loading ?
                ''
                :
                <Grid container sx={{my: 3}}>
                    <Grid item xs={12}>
                        <AgGrid children={data}/>
                    </Grid>
                </Grid>
            }
        </Grid>
    </>);
}