import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import {NumericFormat} from "react-number-format";

export default function EditLocation() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [name, setName] = useState(undefined);
    const [street, setStreet] = useState(undefined);
    const [city, setCity] = useState(undefined);
    const [zip, setZip] = useState(undefined);
    const [enabled, setEnabled] = useState(true);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (id) {
            setIsEdit(true);

            ApiService.getLocationById(id).then(response => response.json()).then(response => {
                const location = response;
                setName(location.name);
                setStreet(location.street);
                setCity(location.city);
                setZip(location.zip);
                setEnabled(location.enabled);
            })
        } else {
            setIsEdit(false);
        }
    }, [id])

    function saveLocation() {
        if (id) {
            const body = {
                id: id,
                name: name,
                street: street,
                zip: zip,
                city: city,
                enabled: enabled
            }

            ApiService.updateLocation(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/locations")
                }
            })
        } else {
            const body = {
                name: name,
                street: street,
                zip: zip,
                city: city
            }

            ApiService.createLocation(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/locations")
                }
            })
        }
    }

    function checkMandatoryLocationFields() {
        return !(name && street && zip && city);
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Sede' : 'Nuova Sede'}</Typography>
            </Grid>
        </Grid>


        <Grid container sx={{mt: 4}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="name" label="Nome sede" value={name} defaultValue={name} variant="standard"
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   onChange={e => setName(e.target.value)} required fullWidth/>
                        <TextField id="street" label="Indirizzo" value={street} defaultValue={street}
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   variant="standard" fullWidth
                                   onChange={e => setStreet(e.target.value)} required/>
                        <TextField id="city" label="Città" value={city} defaultValue={city} variant="standard"
                                   InputLabelProps={isEdit ? {shrink: true} : ''} fullWidth
                                   onChange={e => setCity(e.target.value)} required/>
                        <NumericFormat
                            allowNegative={false}
                            decimalScale={0}
                            customInput={TextField} id="zip"
                            label="Cap"
                            defaultValue={zip}
                            value={zip}
                            variant="standard"
                            onChange={e => setZip(e.target.value)}
                            fullWidth required/>
                    </Stack>
                </Box>
                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/locations')
                        }}>
                            Annulla
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary" disabled={checkMandatoryLocationFields()}
                                    onClick={() => {
                                        saveLocation()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary" disabled={checkMandatoryLocationFields()}
                                    onClick={() => {
                                        saveLocation()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    </>);
}