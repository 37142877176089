import {Box, Button, FormControlLabel, Grid, MenuItem, Stack, Switch, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import {NumericFormat} from "react-number-format";

export default function EditFunction() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [name, setName] = useState(undefined);
    const [schoolYear, setSchoolYear] = useState(undefined);
    const [users, setUsers] = useState([]);
    const [studentsPrimary, setStudentsPrimary] = useState(0);
    const [studentsFirstClass, setStudentsFirstClass] = useState(0);
    const [studentsSecondClass, setStudentsSecondClass] = useState(0);
    const [arbitraryAmount, setArbitraryAmount] = useState(0);
    const [hasLimit, setHasLimit] = useState(true);
    const [enabled, setEnabled] = useState(true);

    const [budget, setBudget] = useState(undefined);

    const [isEdit, setIsEdit] = useState(false);

    const [catalog, setCatalog] = useState(undefined);
    const [location, setLocation] = useState('');

    const [listOfSchoolYear, setListOfSchoolYear] = useState(undefined);
    const [listOfLocations, setListOfLocations] = useState(undefined);
    const [listOfCatalogs, setListOfCatalogs] = useState(undefined);
    const [listOfUsers, setListOfUsers] = useState([]);

    const label = {inputProps: {'aria-label': 'Color switch demo'}};

    useEffect(() => {
        ApiService.getUsers().then(response => response.json()).then(response => {
            setListOfUsers(response);
        });

        ApiService.getPeriodsEnabled().then(response => response.json()).then(response => {
            const schoolYears = response;
            if (schoolYears.length > 0) {
                setListOfSchoolYear(schoolYears)
            }
        });

        ApiService.getCatalogsEnabled().then(response => response.json()).then(response => {
            const catalogs = response;
            if (catalogs.length > 0) {
                setListOfCatalogs(catalogs);
            }
        });

        ApiService.getLocationsEnabled().then(response => response.json()).then(response => {
            const locations = response;
            if (locations.length > 0) {
                setListOfLocations(locations);
            }
        });

        if (id) {
            setIsEdit(true);

            ApiService.getBudgetById(id).then(response => response.json()).then(response => {
                const budget = response;
                setBudget(budget);
                if (budget.amount === null) {
                    setHasLimit(false);
                }
                setName(budget.name);
                setSchoolYear(budget.schoolYear.id);
                if (budget.location) {
                    setLocation(budget.location?.id);
                } else {
                    setLocation('');
                }

                setCatalog(budget.catalog.id);
                if (budget.users && budget.users.length > 0) {
                    let usersIdArray = [];
                    for (const user of budget.users) {
                        usersIdArray.push(user.id);
                    }
                    setUsers(usersIdArray);
                }
                setStudentsPrimary(budget.primarySchoolStudents);
                setStudentsFirstClass(budget.elementarySchoolFirstCycleStudents);
                setStudentsSecondClass(budget.elementarySchoolSecondCycleStudents);
                if (budget.arbitraryAmount) {
                    setArbitraryAmount(budget.arbitraryAmount / 100);
                }
                setEnabled(budget.enabled);
            })
        } else {
            setIsEdit(false);
        }

    }, [id])

    function saveFunction() {
        if (id) {
            const body = {
                id: Number(id),
                name: name,
                schoolYearId: schoolYear,
                catalogId: catalog,
                locationId: location === '' ? undefined : location,
                users: users,
                hasLimit: hasLimit,
                primarySchoolStudents: Number(studentsPrimary),
                elementarySchoolFirstCycleStudents: Number(studentsFirstClass),
                elementarySchoolSecondCycleStudents: Number(studentsSecondClass),
                arbitraryAmount: budget.amount === null ? undefined : Number(arbitraryAmount * 100),
                enabled: enabled
            }
            ApiService.updateBudget(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/functions")
                }
            })
        } else {
            const body = {
                name: name,
                schoolYearId: schoolYear,
                catalogId: catalog,
                locationId: location === '' ? undefined : location,
                users: users,
                hasLimit: hasLimit,
                primarySchoolStudents: Number(studentsPrimary),
                elementarySchoolFirstCycleStudents: Number(studentsFirstClass),
                elementarySchoolSecondCycleStudents: Number(studentsSecondClass),
                arbitraryAmount: Number(arbitraryAmount * 100)
            }
            ApiService.createBudgets(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/functions")
                }
            })
        }
    }

    function checkMandatoryFunctionFields() {
        return !(name && schoolYear && catalog && hasLimit !== undefined);
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Funzione' : 'Nuova Funzione'}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 4}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="name" label="Nome funzione" value={name} defaultValue={name}
                                   variant="standard" fullWidth
                                   onChange={e => setName(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required/>
                        <TextField
                            select
                            id="schoolYearIdSelect"
                            label={'Seleziona periodo'}
                            required
                            fullWidth
                            variant="standard"
                            value={schoolYear || ''}
                            onChange={(e) => setSchoolYear(e.target.value)}
                        >
                            {listOfSchoolYear && listOfSchoolYear.length > 0 ? listOfSchoolYear.map((schoolYear) => (
                                <MenuItem key={schoolYear.id}
                                          value={schoolYear.id}>{schoolYear.name}</MenuItem>)) : ''}
                        </TextField>
                        <TextField
                            select
                            id="catalogIdSelect"
                            label={'Seleziona catalogo'}
                            required
                            fullWidth
                            variant="standard"
                            value={catalog || ''}
                            onChange={(e) => setCatalog(e.target.value)}
                        >
                            {listOfCatalogs && listOfCatalogs.length > 0 ? listOfCatalogs.map((catalog) => (
                                <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>)) : ''}
                        </TextField>
                        <TextField
                            select
                            id="locationIdSelect"
                            label={'Seleziona sede'}
                            fullWidth
                            variant="standard"
                            value={location || ''}
                            onChange={(e) => setLocation(e.target.value)}
                        >
                            <MenuItem value={''}>Nessuna selezione</MenuItem>)) : ''
                            {listOfLocations && listOfLocations.length > 0 ? listOfLocations.map((location) => (
                                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)) : ''}
                        </TextField>
                        <TextField
                            SelectProps={{
                                multiple: true,
                                value: users || []
                            }}
                            select
                            id="userIdSelect"
                            label={'Seleziona utente'}
                            onChange={(e) => setUsers(e.target.value)}
                            fullWidth
                            variant="standard"
                        >
                            {listOfUsers && listOfUsers.length > 0 ?
                                listOfUsers.map((user) => (
                                    <MenuItem key={user.id}
                                              value={user.id}>{user.firstName + ' ' + user.lastName}</MenuItem>
                                ))
                                :
                                ''}
                        </TextField>

                        <FormControlLabel control={<Switch {...label} defaultChecked/>} checked={!hasLimit}
                                          disabled={isEdit}
                                          label="Budget Illimitato" onChange={(e) => setHasLimit(!e.target.checked)}/>

                        {hasLimit ?
                            <>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="quantity"
                                    label="N. studenti SI"
                                    defaultValue={studentsPrimary}
                                    value={studentsPrimary}
                                    variant="standard"
                                    onChange={e => setStudentsPrimary(e.target.value)}
                                    fullWidth/>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="studentsFirstClass"
                                    label="N. studenti SE 1° ciclo"
                                    defaultValue={studentsFirstClass}
                                    value={studentsFirstClass}
                                    variant="standard"
                                    onChange={e => setStudentsFirstClass(e.target.value)}
                                    fullWidth/>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="studentsSecondClass"
                                    label="N. studenti SE 2° ciclo"
                                    defaultValue={studentsSecondClass}
                                    value={studentsSecondClass}
                                    variant="standard"
                                    onChange={e => setStudentsSecondClass(e.target.value)}
                                    fullWidth/>
                                <NumericFormat decimalSeparator="."
                                               fixedDecimalScale={true}
                                               allowNegative={false}
                                               decimalScale={2}
                                               customInput={TextField} id="arbitraryAmount"
                                               label="Budget aggiuntivo (CHF)"
                                               defaultValue={arbitraryAmount}
                                               value={arbitraryAmount}
                                               variant="standard"
                                               onChange={e => setArbitraryAmount(e.target.value)}
                                               fullWidth required/>
                            </>
                            :
                            ''
                        }

                    </Stack>
                </Box>

                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/functions')
                        }}>
                            Annulla
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary" disabled={checkMandatoryFunctionFields()}
                                    onClick={() => {
                                        saveFunction()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary" disabled={checkMandatoryFunctionFields()}
                                    onClick={() => {
                                        saveFunction()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
}