import {authHeader, authHeaderImage} from "./AuthHeader";

let curLocation = window.location.origin;

class ApiService {

    login(formData) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/login`, {
            method: "POST",
            body: formData
        });
    }

    // PRODUCTS
    getProducts() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/all`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getProductsEnabled() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createProduct(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateProducts(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getProductById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteProductById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    getProductsByCatalogId(page, pageSize, catalogId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/catalog/${catalogId}?page=${page}&pageSize=${pageSize}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getProductsBySearch(page, pageSize, catalogId, query, categoryId) {
        let queryApi = '';
        if (query !== undefined) {
            queryApi = `&query=${query}`;
        }

        let categoryIdApi = '';
        if (categoryId !== '') {
            categoryIdApi = `&categoryId=${categoryId}`;
        }

        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/search?page=${page}&pageSize=${pageSize}&sort=asc&catalogId=${catalogId}${queryApi}${categoryIdApi}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    // LOCATIONS (Sedi)
    getLocations() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/all`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getLocationsEnabled() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createLocation(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateLocation(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    deleteLocationById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    getLocationById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    // BUDGETS (Funzioni)
    getBudgets() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/all`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getBudgetsEnabled() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createBudgets(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateBudget(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getBudgetById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getBudgetsByUserId(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getBudgetsByUserIdForOrders(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/user/${userId}/for-orders`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteBudgetById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    // ORDERS
    getOrders() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getOrderById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    createOrderThirdParties(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/third-parties/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateOrderThirdParties(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/third-parties/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    addOrderItem(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/create-order-item`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateOrderItem(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/update-order-item`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getOrdersByUserId(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getOrdersByStatus(status, catalogId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/status/${status}?catalogId=${catalogId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteOrderByOrderId(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    deleteOrderThirdPartiesByOrderId(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/third-parties/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    deleteOrderItemByIds(orderId, itemId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/delete-order-item/${orderId}/${itemId}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    submitOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/submit/${orderId}`, {
            method: "POST",
            headers: authHeader()
        });
    }

    returnOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/return`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: authHeader()
        });
    }

    cancelOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/cancel/${orderId}`, {
            method: "POST",
            headers: authHeader()
        });
    }

    abortOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/abort/${orderId}`, {
            method: "POST",
            headers: authHeader()
        });
    }

    approveOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/approve/${orderId}`, {
            method: "POST",
            headers: authHeader()
        });
    }

    processOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/process/${orderId}`, {
            method: "POST",
            headers: authHeader()
        });
    }

    partialReturnSingleItemOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/partial-return`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: authHeader()
        });
    }

    partialReturnSubmittedSingleItemOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/partial-return-submitted`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: authHeader()
        });
    }

    // SCHOOL YEARS
    getPeriods() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/all`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getPeriodsEnabled() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createPeriod(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updatePeriod(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getPeriodById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deletePeriodById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }


    // CATALOGS
    getCatalogs() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/all`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getCatalogsEnabled() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createCatalog(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateCatalog(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getCatalogById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteCatalogById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    // CATEGORIES
    getCategories() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/all`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getCategoriesEnabled() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories`, {
            method: "GET",
            headers: authHeader()
        });
    }

    createCategory(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateCategory(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getCategoryById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getCategoriesByCatalogId(catalogId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/product-categories/${catalogId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteCategoryById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    // STOCK
    createStock(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateStock(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getStockQuantityByProductId(productId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/stock-quantity/${productId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getStocksByProductId(productId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/product/${productId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getStockByStockId(stockId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/${stockId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteStockByStockId(stockId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/delete/${stockId}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    //COUPONS
    createCoupon(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    updateCoupon(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        });
    }

    getCouponById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/${id}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    deleteCouponById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    getCoupons() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getCouponsByUserId(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/owner/${userId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    // IMAGE PRODUCT
    uploadImage(productId, body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/upload/product/${productId}`, {
            method: "POST",
            headers: authHeaderImage(),
            body: body,
        });
    }

    getImage(imageName) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/product-image/${imageName}`, {
            method: "GET",
            headers: authHeaderImage()
        });
    }

    deleteImage(productId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/delete/product/${productId}`, {
            method: "DELETE",
            headers: authHeader()
        });
    }

    //USERS
    getUsers() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users`, {
            method: "GET",
            headers: authHeader()
        });
    }

    //LOGS
    getLogsSearch(logType, dateFrom, dateTo, userId) {
        let userParam = '';
        if (userId) {
            userParam = `&userId=${userId}`
        }
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/search?eventType=${logType}&from=${encodeURIComponent(dateFrom)}&to=${encodeURIComponent(dateTo)}${userParam}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getStocksLogs() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/stocks`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getStocksByUserIdLogs(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/stocks/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getProductsLogs() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/products`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getProductsByUserIdLogs(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/products/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getOrdersLogs() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/orders`, {
            method: "GET",
            headers: authHeader()
        });
    }

    getOrdersByUserIdLogs(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/orders/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        });
    }
}

export default new ApiService();
