import React, {useEffect, useState} from "react";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

export default function PeriodDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [period, setPeriod] = useState(undefined);
    const [loading, setLoading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            ApiService.getPeriodById(id).then(response => response.json()).then(response => {
                setLoading(false);
                setPeriod(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function archivePeriod() {
        const body = {
            id: period.id,
            name: period.name,
            start: period.start,
            end: period.end,
            primarySchoolStudentBudget: period.primarySchoolStudentBudget,
            elementarySchoolFirstCycleStudentBudget: period.elementarySchoolFirstCycleStudentBudget,
            elementarySchoolSecondCycleStudentBudget: period.elementarySchoolSecondCycleStudentBudget,
            enabled: !period.enabled
        }

        ApiService.updatePeriod(body).then(response => response.json()).then(response => {
            if (response) {
                setPeriod(response);
                navigate("/period-details/" + id)
            }
        })
    }

    function deletePeriod() {
        ApiService.deletePeriodById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate("/periods");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Periodo</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        {!loading ?
                            <>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                                    periodo: </Typography> {period?.name}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data
                                    d'inizio:</Typography> {period?.startFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data di
                                    fine: </Typography> {period?.endFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget pro
                                    capite
                                    SI (CHF): </Typography> {period?.primarySchoolStudentBudgetFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget pro
                                    capite SE
                                    1°
                                    ciclo
                                    (CHF): </Typography> {period?.elementarySchoolFirstCycleStudentBudgetFormatted}
                                </Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget pro
                                    capite SE
                                    2°
                                    ciclo
                                    (CHF): </Typography> {period?.elementarySchoolSecondCycleStudentBudgetFormatted}
                                </Typography>
                            </>
                            :
                            ''
                        }

                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <Stack spacing={1.25}>
                    <Button variant="contained" color="primary" onClick={() => navigate(('/edit-period/' + id))}
                            fullWidth disabled={!period?.enabled}>
                        Modifica
                    </Button>
                    <Button variant="flip" fullWidth onClick={() => archivePeriod()}>
                        {period?.enabled ? 'Archivia' : 'Ripristina'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => confirmOpen()} fullWidth
                            disabled={!period?.enabled}>
                        Elimina
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/periods')
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deletePeriod} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questo periodo bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}