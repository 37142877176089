import {Alert, Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {useState} from "react";
import logo from "../assets/img/lvga-logo.png";
import {parseJwt, setToken, setUser} from "../services/AuthService";
import BottomBar from "../components/BottomBar";
import ApiService from "../services/ApiService";

export default function Login() {
    const [error, setError] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const formData = new FormData();
        formData.append("username", data.get('username'));
        formData.append("password", data.get('password'));


        ApiService.login(formData).then(response => response.json())
            .then(response => {
                setToken(response.token);
                const decoded = parseJwt(response.token);
                setUser(decoded);
                window.location.replace(`${window.location.origin}`);
            })
            .catch(response => setError(true));
    }

    return (
        <Grid
            container
            component="main"
            direction="column"
            sx={{
                minHeight: '100vh',
            }}
        >
            <Grid item
                  sx={{
                      flexGrow: 1
                  }}
            >
                <Grid container
                      sx={{
                          alignItems: 'stretch',
                          height: {
                              xs: 'calc(100vh - 62px)',
                              md: 'calc(100vh - 38px)',
                          },
                          flex: '1'
                      }}
                >
                    <Grid item xs={12} md={6}
                          sx={{
                              height: '100%',
                              flex: '1',
                              p: 4,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end'
                          }}
                    >
                        <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate
                             sx={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 width: {
                                     xs: '100%',
                                     lg: '65%',
                                 }
                             }}
                        >
                            <img src={logo} alt="lvga logo" width="70" height="82"/>
                            <Typography component="h1" variant="h5" sx={{mt: 2, mb: 1.5, fontWeight: 400}}>
                                Accedi
                            </Typography>
                            <Stack
                                spacing={2.5}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    label="Username"
                                    variant="standard"
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <VisibilityOffOutlinedIcon/> :
                                                        <VisibilityOutlinedIcon/>}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }

                                    }
                                />
                                {error === true ?
                                    <Alert severity="error">Email o password errati, riprova!</Alert>
                                    :
                                    ''
                                }
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    Accedi
                                </Button>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}
                          sx={{
                              bgcolor: 'common.black',
                              p: 4,
                              display: {
                                  xs: "none",
                                  md: "flex"
                              },
                              alignItems: 'center'
                          }}
                    >
                        <Typography
                            variant="h2"
                            color="common.white"
                            sx={{
                                fontWeight: 400
                            }}
                        >RiMa</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}
                  sx={{
                      flexShrink: '0'
                  }}
            >
                <BottomBar/>
            </Grid>
        </Grid>
    );
}