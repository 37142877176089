import {Box, Button, Chip, Grid, MenuItem, Step, Stepper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import {ShoppingCartOutlined} from "@mui/icons-material";
import CustomStepperLabel from "../components/Stepper";
import CardDetailCart from "../card/CardDetail";
import ProductCard from "../components/ProductCard";
import InfiniteScroll from "react-infinite-scroll-component";

function getSteps() {
    return ['Informazioni', 'Prodotti', 'Conferma'];
}

export default function OrderProducts() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const edit = path.pathname.split('/')[3];

    const [activeStep, setActiveStep] = useState(1);
    const steps = getSteps();

    const [products, setProducts] = useState([]);

    const [catalog, setCatalog] = useState(undefined);
    const [category, setCategory] = useState('');
    const [listOfCategories, setListOfCategories] = useState(undefined);
    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const [moreContent, setMoreContent] = useState(true);

    const [query, setQuery] = useState(undefined);
    const [page] = useState(0);
    const [pageSize, setPageSize] = useState(28);

    const [budgetSelected, setBudgetSelected] = useState(undefined);

    useEffect(() => {
        if (edit === 'edit') {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        setActiveStep(1)

        ApiService.getOrderById(id).then(response => response.json()).then(response => {
            setOrder(response);

            setCatalog(response?.budget?.catalog);
            if (response?.budget?.amount === null) {
                setBudgetSelected('Illimitato');
            } else {
                setBudgetSelected((response.budget?.availableAmount / 100).toFixed(2) + ' CHF');
            }
            filterProducts(response?.budget?.catalog?.id);

            ApiService.getCategoriesByCatalogId(response?.budget?.catalog.id).then(response => response.json()).then(response => {
                const categories = response;
                if (categories.length > 0) {
                    setListOfCategories(categories);
                }
            });
        });

        // eslint-disable-next-line
    }, [id, edit])

    function filterProducts(catalogId) {
        if (products.length > 0) {
            setProducts([]);
        }

        ApiService.getProductsBySearch(page, pageSize, catalogId, query, category).then(response => response.json()).then(response => {
            const newProducts = response;
            if (newProducts.products.length > 0) {
                setProducts(newProducts.products);
            }

            if (newProducts.products.length < 28) {
                setMoreContent(false);
            } else {
                setMoreContent(true);
            }
        });
    }

    function fetchMoreProducts() {
        setTimeout(() => {
            const plus = pageSize + 28;
            setPageSize(plus);

            ApiService.getProductsBySearch(page, plus, catalog.id, query, category).then(response => response.json()).then(response => {
                setProducts(response.products);

                if (response.products.length < 28) {
                    setMoreContent(false);
                } else {
                    setMoreContent(true);
                }
            });
        }, 0);
    }

    function selectCategory(value) {
        setCategory(value);
        if (value === '') {
            filterProducts(catalog.id);
        }
    }

    const changeOrderInitial = (orderFinal) => {
        setOrder(orderFinal);
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.keyCode === 13) {
            filterProducts(catalog.id);
        }
    };

    return (
        <>
            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <Breadcrumb/>
                    <Typography variant="h5" component="h2"
                                sx={{fontWeight: '500'}}>{isEdit ? 'Modifica ordine' : 'Nuovo Ordine'}</Typography>
                </Grid>
            </Grid>

            <Box
                component={'div'}
                sx={{
                    display: 'grid',
                    width: '100%',
                    mt: 3,
                    columnGap: 1,
                    rowGap: 1,
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                    },
                    gridTemplateRows: {
                        xs: 'auto',
                        md: 'auto'
                    },
                    gridTemplateAreas: {
                        xs: `
                            'step'
                            'box'
                            'desc'
                            'btn'
                            'search'
                            `,
                        md: `
                            'step step step step step step step step btn btn btn btn'
                            '. . . . . . . . box box box box'
                            'desc desc desc desc desc desc desc desc box box box box'
                            'search search search search search search search search box box box box'
                            `,
                    }
                }}
            >
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'step',
                     }}
                >
                    <Grid container>
                        <Grid item xs={12} lg={9}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <CustomStepperLabel label={label}/>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Grid>
                </Box>
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'btn',
                     }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            navigate('/order-cart/' + id + (isEdit ? '/edit' : ''));
                        }}
                        fullWidth
                        startIcon={<ShoppingCartOutlined/>}
                        size={'large'}
                    >
                        Vai al carrello
                        {order?.items?.length > 0 ?
                            <Chip label={order?.items?.length} size={'small'}
                                  sx={{
                                      color: '#c23052',
                                      background: '#ffffff',
                                      ml: 1
                                  }}
                            />
                            :
                            ''}

                    </Button>
                </Box>

                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'box',
                         mt: {
                             xs: 1.25
                         }
                     }}
                >
                    {order && order.budget ?
                        <CardDetailCart show={true} budget={budgetSelected} total={order.totalFormatted}
                                        user={order.user}
                                        period={order.budget.schoolYear.name} location={order.deliveryLocation.name}
                                        budgetName={order.budget.name} notes={order.notes}/>
                        :
                        ""}

                </Box>
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'desc',
                     }}
                >
                    <Box>
                        <Typography sx={{mb: 0.75}}>Catalogo: <Typography component={'span'}
                                                                          sx={{fontWeight: 600}}>{order?.budget?.catalog?.name}</Typography></Typography>
                    </Box>
                </Box>
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'search',
                     }}
                >
                    <Grid container justifyContent={{xs: "flex-end", md: "flex-start"}} columns={{xs: 5, md: 8, lg: 8}}
                          rowSpacing={1.25} columnSpacing={1.5} sx={{mt: 0.75}}>
                        <Grid item xs={5} md={6} lg={4}>
                            <TextField id="query" label="Cerca per codice o nome prodotto" value={query}
                                       defaultValue={query}
                                       variant="standard"
                                       onChange={e => setQuery(e.target.value)}
                                       onKeyDown={handleKeypress}
                                       InputLabelProps={isEdit ? {shrink: true} : ''} fullWidth/>
                        </Grid>
                        <Grid item xs={5} md={6} lg={2}>
                            <TextField
                                select
                                id="categoryIdSelect"
                                label={'Seleziona categoria'}
                                fullWidth
                                variant="standard"
                                value={category || ''}
                                onChange={(e) => selectCategory(e.target.value)}
                            >
                                <MenuItem value={''}>Nessuna selezione</MenuItem>
                                {listOfCategories && listOfCategories.length > 0 ? listOfCategories.map((category) => (
                                    <MenuItem key={category.id}
                                              value={category.id}>{category.name}</MenuItem>)) : ''}
                            </TextField>

                        </Grid>
                        <Grid item xs={2} md={2} lg={1}>
                            <Button variant="contained" color="primary" sx={{mt: 1}} fullWidth
                                    onClick={() => filterProducts(catalog.id)}>
                                Cerca
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>


            <InfiniteScroll
                dataLength={products.length}
                next={fetchMoreProducts}
                hasMore={moreContent}
                loader={<h4>In caricamento...</h4>}
            >
                <Grid container spacing={2} sx={{mt: 3, mb: 3}}>
                    {products && products.length > 0 ?
                        products.map((product) =>
                            <ProductCard product={product} orderInitial={order ? order : undefined}
                                         changeOrderInitial={changeOrderInitial}/>
                        )
                        :
                        <Grid item xs={12}>
                            <p>Nessun prodotto trovato</p>
                        </Grid>
                    }
                </Grid>
            </InfiniteScroll>


            <Grid container justifyContent={'space-between'} columnSpacing={2}>
                <Grid item xs={5}>
                    <Button variant="outlined" color="secondary" onClick={() => {
                        navigate('/');
                    }}>
                        Esci e salva
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}