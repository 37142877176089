import {Box, Button, Grid, MenuItem, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import ClearIcon from '@mui/icons-material/Clear';
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {NumericFormat} from "react-number-format";

export default function EditProduct() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const curLocation = window.location.origin;

    const [name, setName] = useState(undefined);
    const [price, setPrice] = useState(undefined);
    const [image, setImage] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [catalog, setCatalog] = useState(undefined);
    const [category, setCategory] = useState(undefined);
    const [quantity, setQuantity] = useState(undefined);
    const [unitDescription, setUnitDescription] = useState(undefined);
    const [enabled, setEnabled] = useState(true);

    const [isEdit, setIsEdit] = useState(false);

    const [listOfCategories, setListOfCategories] = useState(undefined);
    const [listOfCatalogs, setListOfCatalogs] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        ApiService.getCatalogsEnabled().then(response => response.json()).then(response => {
            const catalogs = response;

            if (catalogs.length > 0) {
                setListOfCatalogs(catalogs);
            }
        });

        ApiService.getCategoriesEnabled().then(response => response.json()).then(response => {
            const categories = response;

            if (categories.length > 0) {
                setListOfCategories(categories);
            }
        });

        if (id) {
            setIsEdit(true);

            fetchProductById(id);
        } else {
            setIsEdit(false);
        }
        // eslint-disable-next-line
    }, [id]);

    function checkMandatoryProductFields() {
        return !(name && code && price && catalog && quantity);
    }

    function saveProduct() {
        const id = path.pathname.split('/')[2];
        if (id) {
            const body = {
                id: id,
                name: name,
                code: code,
                price: (Number(price) * 100),
                catalogId: catalog,
                minimumStockQuantity: Number(quantity),
                categoryId: category !== '' ? category : undefined,
                unitDescription: unitDescription,
                enabled: enabled
            }
            ApiService.updateProducts(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/products")
                }
            })
        } else {
            const body = {
                name: name,
                price: (Number(price) * 100),
                code: code,
                catalogId: catalog,
                categoryId: category !== '' ? category : undefined,
                minimumStockQuantity: Number(quantity),
                unitDescription: unitDescription
            }
            ApiService.createProduct(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/products")
                }
            })
        }
    }

    async function saveImage(value) {
        const body = new FormData();
        body.append('file', value);

        await ApiService.uploadImage(id, body).then(response => {
            if (response) {
                fetchProductById(id);
            }
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function fetchProductById(id) {
        ApiService.getProductById(id).then(response => response.json()).then(response => {
            const product = response;
            setName(product.name);
            setPrice(Number(product.price) / 100);
            if (product.image) {
                const imageSplitted = `${curLocation}${process.env.REACT_APP_SERVER_URL}${product.image}`;
                setImage(imageSplitted);
            }
            setCode(product.code);
            setCatalog(product.catalog.id);
            if (product.category) {
                setCategory(product.category.id);
            }
            setQuantity(product.minimumStockQuantity);
            if (product.unitDescription) {
                setUnitDescription(product.unitDescription);
            }
            setEnabled(product.enabled)
        })
    }

    const confirmDelete = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteImage() {
        ApiService.deleteImage(id).then(response => {
            if (response) {
                setImage(undefined);
                setOpenDialog(false);
            }
        })
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Prodotto' : 'Nuovo Prodotto'}</Typography>
            </Grid>
        </Grid>

        <Box component={'form'}>
            <Grid container sx={{mt: 4}} columnSpacing={2.5}>
                <Grid item xs={12} md={8} lg={4}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="code" label="Codice prodotto" value={code} defaultValue={code}
                                   variant="standard"
                                   onChange={e => setCode(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required fullWidth/>
                        <TextField id="name" label="Nome prodotto" value={name} defaultValue={name}
                                   variant="standard"
                                   onChange={e => setName(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required fullWidth/>
                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       decimalScale={2}
                                       customInput={TextField} id="price"
                                       label="Costo unitario (CHF)"
                                       defaultValue={price}
                                       value={price}
                                       variant="standard"
                                       onChange={e => setPrice(e.target.value)}
                                       fullWidth required/>
                        <TextField
                            select
                            id="catalogIdSelect"
                            label={'Seleziona catalogo'}
                            required
                            fullWidth
                            variant="standard"
                            value={catalog || ''}
                            onChange={(e) => setCatalog(e.target.value)}
                        >
                            {listOfCatalogs && listOfCatalogs.length > 0 ? listOfCatalogs.map((catalog) => (
                                <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>)) : ''}
                        </TextField>

                        <TextField
                            select
                            id="categoryIdSelect"
                            label={'Seleziona categoria'}
                            fullWidth
                            variant="standard"
                            value={category || ''}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <MenuItem value={''}>Nessuna selezione</MenuItem>
                            {listOfCategories && listOfCategories.length > 0 ? listOfCategories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)) : ''}
                        </TextField>

                        <TextField id="unitDescription" label="Descrizione unità" value={unitDescription}
                                   defaultValue={unitDescription}
                                   variant="standard" onChange={e => setUnitDescription(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                        />
                        <NumericFormat
                                       allowNegative={false}
                                       decimalScale={0}
                                       customInput={TextField} id="quantity"
                                       label="Punto di riordino"
                                       defaultValue={quantity}
                                       value={quantity}
                                       variant="standard"
                                       onChange={e => setQuantity(e.target.value)}
                                       fullWidth required/>
                    </Stack>
                </Grid>
                {isEdit ?
                    <Grid item xs={12} md={4} lg={3}>
                        <input
                            accept="image/*"
                            style={{display: 'none'}}
                            id="raised-button-file"
                            multiple
                            type="file"
                            onChange={(e) => saveImage(e.target.files[0])}
                        />
                        <label htmlFor="raised-button-file">
                            <Button component="span" variant="contained" color="primary" sx={{mb: 1.5}} fullWidth
                                    disabled={!isEdit}>
                                Seleziona foto
                            </Button>
                        </label>
                        {image ?
                            <>
                                <Paper
                                    sx={{
                                        p: 1,
                                        "& img": {
                                            width: "100%",
                                            height: 'auto'
                                        }
                                    }}
                                >
                                    <img src={image} alt={""}/>
                                </Paper>
                                <Box
                                    sx={{
                                        mt: 1.5,
                                    }}
                                >
                                    <Button variant="outlined" color='secondary' onClick={() => confirmDelete()}
                                            startIcon={<ClearIcon/>}>
                                        Elimina foto
                                    </Button>
                                </Box>
                            </>

                            :
                            ''}
                    </Grid>
                    :
                    ''
                }
            </Grid>
        </Box>

        <Grid container sx={{mt: 3}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/products')
                        }}>
                            Annulla
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary" disabled={checkMandatoryProductFields()}
                                    onClick={() => {
                                        saveProduct()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary" disabled={checkMandatoryProductFields()}
                                    onClick={() => {
                                        saveProduct()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteImage} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questa foto bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}