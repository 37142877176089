import React, {useEffect, useMemo, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {Link, useNavigate} from "react-router-dom";
import {enabledFormatter} from "../configuration/agGridFormatterFunctions";

const data = {
    columnDefs: [
        {
            headerName: "Nome periodo",
            field: "name",
            sortable: true,
            minWidth: 150,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data d'inizio",
            field: "startFormatted",
            minWidth: 130,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data di fine",
            field: "endFormatted",
            minWidth: 130,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget pro capite SI (CHF)",
            field: "primarySchoolStudentBudgetFormatted",
            minWidth: 100, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget pro capite SE 1° ciclo (CHF)",
            field: "elementarySchoolFirstCycleStudentBudgetFormatted",
            minWidth: 100, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget pro capite SE 2° ciclo (CHF)",
            field: "elementarySchoolSecondCycleStudentBudgetFormatted",
            minWidth: 100, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/period-details/' + params.data.id} className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/edit-period/' + params.data.id} className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function Periods() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        ApiService.getPeriods().then(response => response.json()).then(response => {
            setLoading(false);
            const schoolYears = response;
            if (schoolYears.length > 0) {
                data.rowData = schoolYears;
            }
        });
    }, [])

    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 150,
            filter: true,
        };
    }, []);

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Periodi</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate("/edit-period")
                        }}>
                    Crea periodo
                </Button>
            </Grid>
        </Grid>
        {loading ?
            ''
            :

            <Grid container sx={{my: 3}}>
                <Grid item xs={12}>
                    <AgGrid children={data} defaultColDef={defaultColDef}/>
                </Grid>
            </Grid>
        }
    </>);
}