import {Grid, Typography} from "@mui/material";
import Breadcrumb from "../components/Breadcrumb";
import React from "react";

export default function UserManual() {

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>Manuale Utente</Typography>
            </Grid>
        </Grid>
    </>);
}