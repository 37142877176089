import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import AgGrid from "./AgGrid";
import ApiService from "../services/ApiService";
import {enabledFormatter} from "../configuration/agGridFormatterFunctions";

const data = {
    columnDefs: [
        {
            headerName: "ID Buono",
            field: "idComment",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Utente",
            field: "owner.fullName",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Valore (CHF)",
            field: "amountFormatted",
            minWidth: 60,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. Fattura",
            field: "invoiceNumber",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/coupon-details/' + params.data.id} className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/edit-coupon/' + params.data.id} className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }

                    </Grid>

                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ], defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function Coupons() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        ApiService.getCoupons().then(response => response.json()).then(response => {
            setLoading(false);
            const coupons = response;
            if (coupons.length > 0) {
                data.rowData = coupons;
            }
        });
    }, []);

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs="auto">
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Buoni acquisto</Typography>
            </Grid>
            <Grid item xs="auto">
                <Button variant="contained" color="primary" onClick={() => navigate('/edit-coupon')}>
                    Crea buono aquisto
                </Button>
            </Grid>
        </Grid>

        {!loading ?
            <Grid container sx={{my: 3}}>
                <Grid item xs={12}>
                    <AgGrid children={data}/>
                </Grid>

            </Grid>
            :
            ''
        }
    </>);
}