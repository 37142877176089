import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import AgGrid from "../components/AgGrid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import ApiService from "../services/ApiService";
import {statusFormatter} from "../configuration/agGridFormatterFunctions";
import {getUser} from "../services/AuthService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

const dataOrders = {
    columnDefs: [
        {
            headerName: "Creato da",
            field: "createdBy",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Periodo",
            field: "budget.schoolYear.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Totale (CHF)",
            field: "totalFormatted",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Sede fornitura",
            field: "budget.location?.name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data creazione",
            field: "createdAtFormatted",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Stato ordine",
            field: "statusLabel",
            cellRenderer: statusFormatter,
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'} to={'/order-user-details/' + params.data.id}>
                                Dettaglio
                            </Link>
                        </Grid>
                        {params.data.status !== 'IN_PROGRESS' ?
                            ''
                            :
                            <Grid item>
                                <Link
                                    className={'muiBtn muiBtnSecondary'}
                                    to={'/order-info/' + params.data.id} disabled={!params.data.enabled}
                                >
                                    Modifica
                                </Link>
                            </Grid>
                        }

                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

const dataCoupons = {
    columnDefs: [
        {
            headerName: "ID Buono",
            field: "idComment",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Utente",
            field: "owner.fullName",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Valore (CHF)",
            field: "amountFormatted",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

const dataFunctions = {
    columnDefs: [
        {
            headerName: "Nome funzione",
            field: "name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget attuale (CHF)",
            field: "availableAmountFormatted",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget iniziale (CHF)",
            field: "amountFormatted",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Budget aggiuntivo (CHF)",
            field: "arbitraryAmountFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SI",
            field: "primarySchoolStudents",
            minWidth: 120,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 1° ciclo",
            field: "elementarySchoolFirstCycleStudents",
            minWidth: 120,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 2° ciclo",
            field: "elementarySchoolSecondCycleStudents",
            minWidth: 120,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function HomeUser() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [noBudgetModal, setNoBudgetModal] = useState(false);
    const user = getUser();

    useEffect(() => {
        ApiService.getBudgetsByUserId(user.id).then(response => response.json()).then(response => {
            if (response && response.budgets.length > 0) {
                setNoBudgetModal(false)
            } else {
                setNoBudgetModal(true)
            }
        });

        setLoading(true);
        ApiService.getOrdersByUserId(user.id).then(response => response.json()).then(response => {
            const orders = response;
            if (orders.orders.length > 0) {
                dataOrders.rowData = orders.orders;
            }
            ApiService.getCouponsByUserId(user.id).then(response => response.json()).then(response => { //TODO: getCouponsById(3)
                const coupons = response;
                if (coupons.length > 0) {
                    dataCoupons.rowData = coupons;
                }
                ApiService.getBudgetsByUserId(user.id).then(response => response.json()).then(response => {
                    setLoading(false);
                    const budgets = response;
                    if (budgets.budgets.length > 0) {
                        dataFunctions.rowData = budgets.budgets;
                    }
                });
            });
        });
        // eslint-disable-next-line
    }, []);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNoBudgetModal(false);
    };

    return (
        <>
            <Grid container sx={{mt: 2}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid xs={12} md={6}>
                            <Typography variant="h5" component="h2"
                                        sx={{fontWeight: '500', mb: {xs: 2, sm: 0}}}>Homepage</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Paper
                component={'div'}
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderTop: '2px solid #c23052',
                    p: 2,
                    my: 3
                }}
            >
                <Grid container columnSpacing={3} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>I miei ordini</Typography>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button variant={'contained'} color={'primary'} onClick={() => {
                                navigate('/order-info')
                            }}>
                                Crea ordine
                            </Button>
                        </Box>
                    </Grid>
                    {!loading ?
                        <Grid item xs={12}>
                            <Box sx={{mt: 2}}>
                                <AgGrid children={dataOrders}/>
                            </Box>
                        </Grid>
                        :
                        ''
                    }
                </Grid>
            </Paper>

            <Paper
                component={'div'}
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderTop: '2px solid #c23052',
                    p: 2,
                    my: 5
                }}
            >
                <Grid container columnSpacing={3} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>I miei buoni
                            acquisto</Typography>
                    </Grid>
                    {!loading ?
                        <Grid item xs={12}>
                            <Box sx={{mt: 2}}>
                                <AgGrid children={dataCoupons}/>
                            </Box>
                        </Grid>
                        :
                        ''
                    }
                </Grid>
            </Paper>

            <Grid container columnSpacing={3} justifyContent={'space-between'} sx={{my: 5}}>
                <Grid item>
                    <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Le mie funzioni</Typography>
                </Grid>
                {!loading ?
                    <Grid item xs={12}>
                        <Box sx={{mt: 2}}>
                            <AgGrid children={dataFunctions}/>
                        </Box>
                    </Grid>
                    :
                    ''
                }
            </Grid>

            {noBudgetModal ?
                <Dialog
                    open={noBudgetModal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ReportProblemOutlinedIcon sx={{mr: 1}}/> Attenzione
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography>Non sono presenti funzioni associate alla tua utenza.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()} variant={'contained'} color={'primary'}>
                            Ok, ho capito
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                ''}

        </>
    );
}