import {Box, Button, Grid, MenuItem, Step, Stepper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import CustomStepperLabel from "../components/Stepper";
import CardDetailCart from "../card/CardDetail";
import {getUser} from "../services/AuthService";

function getSteps() {
    return ['Informazioni', 'Prodotti', 'Conferma'];
}

export default function OrderInfo() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const currentUser = getUser();
    const [manager] = useState(currentUser.ROLE[0].authority === 'MANAGER' || currentUser.ROLE[0].authority === 'SECRETARIAT');

    const steps = getSteps();
    const [activeStep, setActiveStep] = useState(0);

    const [user, setUser] = useState(undefined);
    const [budget, setBudget] = useState(undefined);
    const [deliveryLocation, setDeliveryLocation] = useState(undefined);
    const [note, setNote] = useState(undefined);
    const [isEdit, setIsEdit] = useState(false);
    const [locationEditable, setLocationEditable] = useState(false);

    const [listOfBudgets, setListOfBudgets] = useState(undefined);
    const [listOfLocations, setListOfLocations] = useState(undefined);
    const [listOfUsers, setListOfUsers] = useState([]);

    const [budgetSelected, setBudgetSelected] = useState(undefined);

    const [seeBudgets, setSeeBudgets] = useState(true);

    useEffect(() => {
        setActiveStep(0);
        if (!manager) {
            setUser(3);
            fetchBudgets(3);
        }

        ApiService.getUsers().then(response => response.json()).then(response => {
            setListOfUsers(response);
        });

        if (id) {
            setIsEdit(true);

            ApiService.getOrderById(id).then(response => response.json()).then(response => {
                const order = response;
                setUser(order.user.id);
                setBudget(order.budget.id);
                fetchBudgets(order.user.id);
                setNote(order.notes);
                if (order.budget.availableAmount === null) {
                    setBudgetSelected('Illimitato');
                } else {
                    setBudgetSelected((response.budget?.availableAmount / 100).toFixed(2) + ' CHF');
                }
                fetchLocations(order.budget.location?.id);
            })
        } else {
            setIsEdit(false);
        }

    }, [id, manager]);

    function saveAndProceed() {
        if (manager) {
            if (id) {
                const body = {
                    id: Number(id),
                    notes: note,
                    budgetId: budget,
                    deliveryLocationId: deliveryLocation,
                    userId: user
                }
                ApiService.updateOrderThirdParties(body).then(response => response.json()).then(response => {
                    if (response) {
                        navigate("/order-products/" + id);
                    }
                })
            } else {
                const body = {
                    notes: note,
                    budgetId: budget,
                    deliveryLocationId: deliveryLocation,
                    userId: user
                }
                ApiService.createOrderThirdParties(body).then(response => response.json()).then(response => {
                    if (response) {
                        navigate("/order-products/" + response.id);
                    }
                })
            }
        } else {
            if (id) {
                const body = {
                    id: Number(id),
                    notes: note,
                    budgetId: budget,
                    deliveryLocationId: deliveryLocation
                }
                ApiService.updateOrder(body).then(response => response.json()).then(response => {
                    if (response) {
                        navigate("/order-products/" + id);
                    }
                })
            } else {
                const body = {
                    notes: note,
                    budgetId: budget,
                    deliveryLocationId: deliveryLocation
                }
                ApiService.createOrder(body).then(response => response.json()).then(response => {
                    if (response) {
                        navigate("/order-products/" + response.id);
                    }
                })
            }
        }
    }

    function selectBudget(value) {
        setBudget(value);
        const [budget] = listOfBudgets.filter(budget => budget.id === value);
        if (budget.availableAmount === null) {
            setBudgetSelected('Illimitato');
        } else {
            setBudgetSelected((budget.availableAmount / 100) + 'CHF');
        }

        fetchLocations(budget.location?.id);
    }

    function fetchLocations(locationId) {
        setLocationEditable(true);
        setDeliveryLocation(locationId);

        ApiService.getLocationsEnabled().then(response => response.json()).then(response => {
            const locations = response;
            if (locations.length > 0) {
                setListOfLocations(locations);
            }
        });

    }

    function selectUser(value) {
        setUser(value);
        fetchBudgets(value);
    }

    function fetchBudgets(userId) {
        setSeeBudgets(true);
        ApiService.getBudgetsByUserIdForOrders(userId).then(response => response.json()).then(response => {
            const budgets = response;
            if (budgets.budgets.length > 0) {
                setListOfBudgets(budgets.budgets);
            } else {
                setSeeBudgets(false);
                setListOfBudgets([]);
            }
        });
    }

    function checkMandatoryOrderFields() {
        return manager ? !(user && budget && deliveryLocation) : !(budget && deliveryLocation);
    }

    return (<div>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica ordine' : 'Nuovo Ordine'}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 3}}>
            <Grid item xs={12} sm={6}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <CustomStepperLabel label={label}/>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
        </Grid>


        <Grid container sx={{marginTop: 4}} columnSpacing={{xs: 3, md: 5}} direction={{md: "row-reverse"}}
              justifyContent="flex-end">
            <Grid item xs={12} md={5} lg={4}>
                <Box component={'div'} sx={{mb: 3}}>
                    <CardDetailCart show={!!(isEdit || budgetSelected)} budget={budgetSelected}/>
                </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={4}>
                <Box
                    component={'form'}
                    noValidate
                    autoComplete="off"
                    sx={{
                        '& .MuiTextField-root': {mb: 2},
                    }}
                >
                    {manager ?

                        <TextField
                            select
                            id="userIdSelect"
                            value={user || ''}
                            label={'Seleziona utente'}
                            onChange={(e) => selectUser(e.target.value)}
                            required
                            fullWidth
                            variant="standard"
                        >
                            {listOfUsers && listOfUsers.length > 0 ?
                                listOfUsers.map((user) => (
                                    <MenuItem key={user.id}
                                              value={user.id}>{user.firstName + ' ' + user.lastName}</MenuItem>
                                ))
                                :
                                <></>}
                        </TextField>
                        :
                        <></>
                    }
                    {seeBudgets ?
                        <TextField
                            select
                            disabled={!user}
                            id="budgetIdSelect"
                            label={'Seleziona funzione'}
                            required
                            fullWidth
                            variant="standard"
                            value={budget || ''}
                            onChange={(e) => selectBudget(e.target.value)}
                        >
                            {listOfBudgets && listOfBudgets.length > 0 ? listOfBudgets.map((budget) => (
                                <MenuItem key={budget.id}
                                          value={budget.id}>{budget.name} {budget.location ? '-' + budget.location.name : ''}</MenuItem>)) : <></>}
                        </TextField>
                        :
                        <p style={{color: 'red'}}>All'utente selezionato non è stata associata una funzione. Per favore
                            seleziona un altro utente per poter proseguire con la stesura dell'ordine.</p>
                    }

                    <TextField
                        disabled={!locationEditable}
                        select
                        id="locationIdSelect"
                        label={'Seleziona sede fornitura'}
                        required
                        fullWidth
                        variant="standard"
                        value={deliveryLocation || ''}
                        onChange={(e) => setDeliveryLocation(e.target.value)}
                    >
                        {listOfLocations && listOfLocations.length > 0 ? listOfLocations.map((location) => (
                            <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)) : <></>}
                    </TextField>

                    <TextField
                        id="note"
                        label="Note"
                        defaultValue={note}
                        value={note}
                        variant="standard"
                        onChange={e => setNote(e.target.value)}
                        InputLabelProps={{shrink: true}}
                        multiline
                        rows={5}
                        fullWidth
                    />

                    <Grid container justifyContent={'space-between'} columnSpacing={2}>
                        <Grid item xs={6}>
                            <Button variant="outlined" color="secondary" onClick={() => {
                                navigate(-1)
                            }}>
                                Annulla
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            {isEdit ?
                                <Box sx={{display: 'flex'}}>
                                    <Button variant="contained" color="primary" fullWidth sx={{mr: 1}}
                                            disabled={checkMandatoryOrderFields()}
                                            onClick={() => {
                                                saveAndProceed()
                                            }}>
                                        Salva
                                    </Button>
                                    <Button variant="outlined" color="primary"
                                            onClick={() => navigate('/order-products/' + id + '/edit')}>
                                        Procedi
                                    </Button>
                                </Box>
                                :
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button variant="contained" color="primary" disabled={checkMandatoryOrderFields()}
                                            onClick={() => {
                                                saveAndProceed()
                                            }}>
                                        Salva e procedi
                                    </Button>
                                </Box>
                            }
                        </Grid>

                    </Grid>
                </Box>
            </Grid>

        </Grid>

    </div>);
}