import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack} from "@mui/material";
import AgGrid from "../components/AgGrid";
import Typography from "@mui/material/Typography";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

function confirmStockDelete(params) {
    if (window.confirm('Sicuro che vuoi eliminare l’elemento?')) {
        ApiService.deleteStockByStockId(params.data.id).then(response => response.json()).then(response => {
            window.location.reload();
        })
    }
}

const data = {
    columnDefs: [
        {
            headerName: "Tipologia",
            field: "statusLabel",
            minWidth: 140,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data di creazione",
            field: "createdAtFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Quantità",
            field: "quantity",
            minWidth: 80,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        {(params.data.status === 'SUPPLY' || params.data.status === 'REMOVAL') ?
                            <>
                                <Grid item>
                                    <Link to={'/edit-stock/' + params.data.product.id + '/' + params.data.id}
                                          className={'muiBtn muiBtnSecondary'}>
                                        Modifica
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link className={'muiBtn muiBtnSecondary'}
                                          onClick={() => confirmStockDelete(params)}>
                                        Elimina
                                    </Link>
                                </Grid>
                            </>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            width: 220,
            suppressSizeToFit: true,
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function ProductDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const curLocation = window.location.origin;

    const [product, setProduct] = useState(undefined);
    const [image, setImage] = useState(undefined);
    const [stock, setStock] = useState(undefined);

    const [loading, setLoading] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            fetchStocks(id);

            ApiService.getProductById(id).then(response => response.json()).then(response => {
                const product = response;
                setProduct(product);
                setImage(product.image ? `${curLocation}${process.env.REACT_APP_SERVER_URL}${product.image}` : undefined);
            })

            setLoading(true);
            ApiService.getStocksByProductId(id).then(response => response.json()).then(response => {
                setLoading(false);
                const stocks = response;
                if (stocks.length > 0) {
                    data.rowData = stocks;
                } else {
                    data.rowData = [];
                }
            });

        }
    }, [id, curLocation]);

    function fetchStocks(id) {
        ApiService.getStockQuantityByProductId(id).then(response => response.json()).then(response => {
            const quantity = response.availableQuantity;
            setStock(quantity)
        })
    }

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteProduct() {
        ApiService.deleteProductById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate("/products");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function archiveProduct() {
        const body = {
            id: product.id,
            name: product.name,
            code: product.code,
            price: product.price,
            catalogId: product.catalog.id,
            minimumStockQuantity: product.minimumStockQuantity,
            categoryId: product.category ? product.category.id : undefined,
            unitDescription: product.unitDescription,
            enabled: !product.enabled
        }

        ApiService.updateProducts(body).then(response => response.json()).then(response => {
            if (response) {
                setProduct(response);
                navigate("/product-details/" + id);
            }
        })
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Prodotto</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Codice
                            prodotto: </Typography>{product?.code}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                            prodotto: </Typography>{product?.name}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Costo unitario
                            (CHF): </Typography>{product?.priceFormatted}</Typography>
                        <Typography>
                            <Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Descrizione
                                unità: </Typography>{product?.unitDescription}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Punto di riordino: </Typography>{product?.minimumStockQuantity}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Quantità
                            disponibile: </Typography>{stock}</Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Catalogo: </Typography>{product?.catalog?.name}
                        </Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Categoria
                            prodotto: </Typography>{product?.category?.name}</Typography>
                    </Stack>
                </Paper>
            </Grid>

            {image ?
                <Grid item xs={12} md={6} lg={2}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 1,
                            borderRadius: 0,
                            "& img": {
                                width: "100%",
                                height: 'auto'
                            }
                        }}
                    >
                        <img src={image} alt={""}/>
                    </Paper>
                </Grid>
                :
                ''
            }


            <Grid item xs={12} md={4} lg={2}>
                <Stack spacing={1.25} sx={{mb: 2}}>
                    <Button variant="contained" color="primary" onClick={() => navigate("/edit-stock/" + id + '/new')}
                            disabled={!product?.enabled}
                            sx={{mb: 2.5}}>
                        Crea Fornitura
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => navigate(('/edit-product/' + id))}
                            disabled={!product?.enabled}
                            fullWidth>
                        Modifica
                    </Button>
                    <Button variant="flip" fullWidth onClick={() => archiveProduct()}>
                        {product?.enabled ? 'Archivia' : 'Ripristina'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => confirmOpen()} fullWidth
                            disabled={!product?.enabled}>
                        Elimina
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        {loading ?
            ''
            :
            <>
                {product?.enabled ?
                    <><Typography variant="h6" component="h2" sx={{fontWeight: '500'}}>Forniture</Typography>
                        <Grid container style={{margin: '30px 0'}}>
                            <AgGrid children={data}/>
                        </Grid></>
                    :
                    ''}
            </>
        }

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/products')
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteProduct}
                     title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questo prodotto bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}