import NavigationBar from "../components/NavigationBar";
import {useWindowSize} from "../utils/Resizer";
import NavigationMobile from "../components/NavigationMobile";

export default function Home() {
    const [width] = useWindowSize();

    if (width >= 600) {
        return (
            <NavigationBar/>
        )
    } else {
        return (
            <NavigationMobile/>
        )
    }
}