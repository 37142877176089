import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

export default function EditCatalog() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [catalog, setCatalog] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            ApiService.getCatalogById(id).then(response => response.json()).then(response => {
                setCatalog(response);
            })
        }
    }, [id]);

    const confirmDelete = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteCatalog() {
        ApiService.deleteCatalogById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success) {
                    navigate("/catalogs");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function archiveCatalog() {
        const body = {
            id: catalog.id,
            name: catalog.name,
            description: catalog.description,
            enabled: !catalog.enabled
        }

        ApiService.updateCatalog(body).then(response => response.json()).then(response => {
            if (response) {
                setCatalog(response);
                navigate("/catalog-details/" + id);
            }
        })
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Catalogo</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                        catalogo:</Typography> {catalog?.name}</Typography>
                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Descrizione:</Typography> {catalog?.description}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <Stack spacing={1.25}>
                    <Button variant="contained" color="primary" onClick={() => navigate(('/edit-catalog/' + id))}
                            fullWidth disabled={!catalog?.enabled}>
                        Modifica
                    </Button>
                    <Button variant="flip" fullWidth onClick={() => archiveCatalog()}>
                        {catalog?.enabled ? 'Archivia' : 'Ripristina'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => confirmDelete()} fullWidth
                            disabled={!catalog?.enabled}>
                        Elimina
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/catalogs')
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>


        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteCatalog} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questa catalogo bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}