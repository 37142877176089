import {useState} from "react";
import ListItemText from '@mui/material/ListItemText';
import {List, ListItemIcon, Tooltip} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FeaturedPlayListOutlined from '@mui/icons-material/FeaturedPlayListOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';

import ListItemsElm from "./ListItemsElm";
import {getUser} from "../services/AuthService";

export default function ListItems({open}) {
    const user = getUser();
    let role = '';
    if (user) {
        role = user.ROLE[0].authority;
    }
    const [openCollapse, setOpenCallapse] = useState(false);
    const [openCollapseTwo, setOpenCallapseTwo] = useState(false);
    const [openCollapseThree, setOpenCallapseThree] = useState(false);

    const handleClick = () => {
        setOpenCallapse(!openCollapse);
    };

    const handleClickTwo = () => {
        setOpenCallapseTwo(!openCollapseTwo);
    };

    const handleClickThree = () => {
        setOpenCallapseThree(!openCollapseThree);
    };

    function roleSwitchCase(role) {
        switch (role) {
            case 'MANAGER':
                return <ListItemsElm label={'Home - Ordini'} icon={<Inventory2OutlinedIcon fontSize="small"/>}
                                     open={open} route={''}/>
            case 'USER':
                return <ListItemsElm label={'Home - Ordini'} icon={<Inventory2OutlinedIcon fontSize="small"/>}
                                     open={open} route={'home-user'}/>
            case 'SECRETARIAT':
                return <ListItemsElm label={'Home - Ordini'}
                                     icon={<Inventory2OutlinedIcon fontSize="small"/>} open={open}
                                     route={'home-secretary'}/>
            default:
                console.log('Errore nel caricamento del ruolo utente')
        }
    }

    return (
        <div>
            <List>
                {roleSwitchCase(role)}

                {role === 'SECRETARIAT' ?
                    <ListItemsElm label={'Buoni acquisto'} icon={<ConfirmationNumberOutlinedIcon fontSize="small"/>}
                                  open={open}
                                  route={'coupons'}/>
                    :
                    ''}
                {role === 'MANAGER' ?
                    <>
                        <ListItemButton onClick={handleClick}
                                        sx={{color: 'white', margin: open ? '0.5rem' : '', px: 0.5, py: 0.25, pl: 2,}}>
                            <ListItemIcon
                                sx={{
                                    display: open ? 'none' : 'inline-flex',
                                    color: 'rgba(255, 255, 255, 0.75)',
                                    marginLeft: '5px',
                                    minWidth: '42px',
                                }}
                            >
                                <Tooltip title="Amministrazione" placement="right">
                                    <SettingsOutlinedIcon fontSize="small"/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                primary="Amministrazione"
                                sx={{
                                    opacity: open ? 'inline-block' : 'none',
                                    color: 'white',
                                    padding: '0.625rem 0',
                                }}
                            />
                            {openCollapse ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                <ListItemsElm label={'Utenti'} icon={<PeopleAltOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              route={'users'} submenu={true}/>

                                <ListItemsElm label={'Funzioni'} icon={<BadgeOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={'functions'}/>

                                <ListItemsElm label={'Sedi'} icon={<PlaceOutlinedIcon fontSize="small"/>} open={open}
                                              submenu={true}
                                              route={'locations'}/>

                                <ListItemsElm label={'Prodotti e forniture'}
                                              icon={<CreateOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={'products'}/>

                                <ListItemsElm label={'Categorie prodotti'}
                                              icon={<BookmarksOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={'categories'}/>

                                <ListItemsElm label={'Cataloghi'} icon={<FolderCopyOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={'catalogs'}/>

                                <ListItemsElm label={'Periodi'} icon={<TodayOutlinedIcon fontSize="small"/>} open={open}
                                              submenu={true} route={'periods'}/>

                                <ListItemsElm label={'Consultazione log'}
                                              icon={<FeaturedPlayListOutlined fontSize="small"/>} open={open}
                                              submenu={true} route={'logs'}/>

                            </List>
                        </Collapse>
                    </>
                    :
                    ''
                }

                {(role === 'MANAGER' || role === 'SECRETARIAT') ?
                    <>
                        <ListItemButton onClick={handleClickTwo}
                                        sx={{color: 'white', margin: open ? '0.5rem' : '', px: 0.5, py: 0.25, pl: 2,}}>
                            <ListItemIcon
                                sx={{
                                    display: open ? 'none' : 'inline-flex',
                                    color: 'rgba(255, 255, 255, 0.75)',
                                    marginLeft: '5px',
                                    minWidth: '42px',
                                }}
                            >
                                <Tooltip title="Reports" placement="right">
                                    <BarChartOutlinedIcon fontSize="small"/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                primary="Reports"
                                sx={{
                                    opacity: open ? 'inline-block' : 'none',
                                    color: 'white',
                                    padding: '0.625rem 0',
                                }}
                            />
                            {openCollapseTwo ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openCollapseTwo} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                <ListItemsElm label={'Limite di spesa'}
                                              icon={<FiberManualRecordOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={'cost-limit'}/>
                            </List>
                        </Collapse>
                    </>
                    :
                    ''}

            <>
                <ListItemButton onClick={handleClickThree}
                                sx={{color: 'white', margin: open ? '0.5rem' : '', px: 0.5, py: 0.25, pl: 2,}}>
                    <ListItemIcon
                        sx={{
                            display: open ? 'none' : 'inline-flex',
                            color: 'rgba(255, 255, 255, 0.75)',
                            marginLeft: '5px',
                            minWidth: '42px',
                        }}
                    >
                        <Tooltip title="AIUTO" placement="right">
                            <InfoOutlinedIcon fontSize="small"/>
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        primary="Aiuto"
                        sx={{
                            opacity: open ? 'inline-block' : 'none',
                            color: 'white',
                            padding: '0.625rem 0',
                        }}
                    />
                    {openCollapseThree ? <ExpandLess/> : <ExpandMore/>}
                </ListItemButton>
                <Collapse in={openCollapseThree} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {role === 'MANAGER' ?
                        <ListItemsElm label={'Manuale Manager'} icon={<PersonOutlinedIcon fontSize="small"/>}
                                      open={open}
                                      route={'manager-manual'} submenu={true}/>
                            :
                            ''
                        }

                        <ListItemsElm label={'Manuale Utente'} icon={<SupervisorAccountOutlinedIcon fontSize="small"/>}
                                      open={open}
                                      submenu={true} route={'user-manual'}/>

                    </List>
                </Collapse>
            </>
            </List>
        </div>
    )

}