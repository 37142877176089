import jwtDecode from "jwt-decode";

const JWT_KEY = "token";
const USER = "user";

function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
}

function deleteToken() {
    localStorage.clear();
}

function isAuthenticated() {
    const token = getToken();

    if (token != null) {
        let decodedToken = jwtDecode(token)
        const isExpired = Math.floor(Date.now() / 1000) > decodedToken.exp

        if (isExpired) {
            deleteToken();
        }

        return token && !isExpired
    }

    return !!token;
}

function setToken(JWT_KEY) {
    return localStorage.setItem("token", JWT_KEY);
}

function getToken() {
    return localStorage.getItem(JWT_KEY);
}

function setUser(userObj) {
    localStorage.setItem(USER, JSON.stringify(userObj));
}

function getUser() {
    return JSON.parse(localStorage.getItem(USER));
}

export {
    isAuthenticated,
    getUser,
    setToken,
    getToken,
    parseJwt,
    setUser
};
