import React, {useState} from "react";
import {Box, Card, CardContent, MenuItem, TextField, Typography} from "@mui/material";

export default function CardCartOrderReturn({order, item, updateListOfItemsToReturn}) {
    const [quantityToReturn, setQuantityToReturn] = useState(0);

    function updateReturnedItem(selectedValue) {
        if (Number(selectedValue) === 0 || Number(selectedValue) < 0 ) {
            setQuantityToReturn(1);
        } else {
            setQuantityToReturn(selectedValue);
        }

        const objectToReturn = {
            orderItemId: item.id,
            quantityToReturn: selectedValue
        }
        updateListOfItemsToReturn(objectToReturn);
    }


    return (
        <>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: 0,
                    border: 'none',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
                }}
            >
                <CardContent
                    sx={{
                        p: 1.5,
                        pb: '10px!important'
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            width: '100%',
                            columnGap: 1,
                            rowGap: 1,
                            gridTemplateColumns: {
                                xs: '1fr 1fr 1fr 1fr 1fr',
                                md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                            },
                            gridTemplateRows: {
                                xs: 'auto',
                                md: 'auto'
                            },
                            gridTemplateAreas: {
                                xs: `
                            'code code code availability availability'
                            'title title title title title'
                            'price qnt input'
                            `,
                                md: `
                            'code title title title title availability price unit qnt input' 
                            `,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'code',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography color="textSecondary" sx={{fontSize: '0.875rem'}}> <Typography
                                component={'span'}
                                sx={{display: {md: 'none'}}}>Cod.</Typography> {item.product.code}</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'title',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: {md: '0.875rem'}}}>
                                {item.product.name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'price',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{item.priceFormatted} CHF</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'unit',
                                display: {
                                    xs: 'none',
                                    md: 'inline-flex'
                                },
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{item.product.unitDescription}</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'qnt',
                                display: {
                                    xs: 'none',
                                    md: 'inline-flex'
                                },
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{item.quantity}</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'input',
                            }}
                        >

                            <TextField
                                select
                                disabled={item.quantity === 1}
                                type="number"
                                id="return-quantity"
                                value={quantityToReturn}
                                onChange={(e) => updateReturnedItem(e.target.value)}
                                variant={'standard'}
                                fullWidth
                                sx={{
                                    width: '60px'
                                }}
                            >
                                {(Array.from(Array(item.quantity).keys())).map((number) => (
                                    <MenuItem key={number}
                                              value={number}>{number}</MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}