import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

export default function CouponDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [coupon, setCoupon] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            ApiService.getCouponById(id).then(response => response.json()).then(response => {
                setCoupon(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteCoupon() {
        ApiService.deleteCouponById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate("/coupons");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function cancelCoupon() {
        const body = {
            id: coupon.id,
            idComment: coupon.idComment,
            description: coupon.description,
            supplier: coupon.supplier,
            amount: coupon.amount,
            invoiceNumber: coupon.invoiceNumber,
            budgetId: coupon.budget.id,
            ownerId: coupon.owner.id,
            enabled: !coupon.enabled
        }

        ApiService.updateCoupon(body).then(response => response.json()).then(response => {
            if (response) {
                setCoupon(response);
                navigate("/coupon-details/" + id);
            }
        })
    }

    return (<>
            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <Breadcrumb/>
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Coupon</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}} columnSpacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper
                        component={'div'}
                        elevation={0}
                        sx={{
                            borderRadius: 0,
                            borderTop: '2px solid #c23052',
                            p: 2,
                            mb: 4
                        }}
                    >
                        <Stack spacing={1.25}>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>ID
                                Buono: </Typography>{coupon?.idComment}
                            </Typography>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Descrizione: </Typography>{coupon?.description}
                            </Typography>
                            <Typography>
                                <Typography component={'span'}
                                            sx={{color: 'rgba(0,0,0,.7)'}}>Fornitore: </Typography>{coupon?.supplier}
                            </Typography>
                            <Typography>
                                <Typography component={'span'}
                                            sx={{color: 'rgba(0,0,0,.7)'}}>Utente: </Typography>{coupon?.owner?.fullName}
                            </Typography>
                            <Typography>
                                <Typography component={'span'}
                                            sx={{color: 'rgba(0,0,0,.7)'}}>Funzione: </Typography>{coupon?.budget?.name}
                            </Typography>
                            <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Valore in
                                CHF: </Typography>{coupon?.amountFormatted}</Typography>

                            {coupon?.invoiceNumber ?
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nr. fattura
                                    SAP: </Typography>{coupon?.invoiceNumber}</Typography>
                                :
                                ''
                            }
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                    <Stack spacing={1.25}>
                        <Button variant="contained" color="primary" onClick={() => navigate(('/edit-coupon/' + id))}
                                disabled={!coupon?.enabled}
                                fullWidth>
                            Modifica
                        </Button>
                        <Button variant="flip" fullWidth onClick={() => cancelCoupon()}>
                            {coupon?.enabled ? 'Annulla' : 'Riattiva'}
                        </Button>
                        <Button variant="outlined" onClick={() => confirmOpen()} fullWidth
                                disabled={!coupon?.enabled}>
                            Elimina
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 5}}>
                <Grid item xs={12}>
                    <Button variant="flip"
                            onClick={() => {
                                navigate('/coupons')
                            }}>
                        Indietro
                    </Button>
                </Grid>
            </Grid>

            <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteCoupon}
                         title={'Attenzione!'}
                         body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

            <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'}
                           title={'Attenzione!'}
                           duration={10000}
                           body={'Per poter eliminare questo buono bisogna rimuovere tutte le relazioni associate ad esso.'}/>
        </>
    );
}