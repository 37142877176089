import React, {useEffect, useState} from "react";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

export default function CategoryDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [category, setCategory] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            ApiService.getCategoryById(id).then(response => response.json()).then(response => {
                setCategory(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteCategory() {
        ApiService.deleteCategoryById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate("/categories");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function archiveCategory() {
        const body = {
            id: category.id,
            name: category.name,
            description: category.description,
            enabled: !category.enabled
        }

        ApiService.updateCategory(body).then(response => response.json()).then(response => {
            if (response) {
                setCategory(response);
                navigate("/category-details/" + id);
            }
        })
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Categoria</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                        categoria:</Typography> {category?.name}</Typography>
                    <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Descrizione:</Typography> {category?.description}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <Stack spacing={1.25}>
                    <Button variant="contained" color="primary" onClick={() => navigate(('/edit-category/' + id))}
                            disabled={!category?.enabled}
                            fullWidth>
                        Modifica
                    </Button>
                    <Button variant="flip" fullWidth onClick={() => archiveCategory()}>
                        {category?.enabled ? 'Archivia' : 'Ripristina'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => confirmOpen()} fullWidth
                            disabled={!category?.enabled}>
                        Elimina
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/categories')
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteCategory} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questa categoria bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}