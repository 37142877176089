import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {enabledFormatter} from "../configuration/agGridFormatterFunctions";

const data = {
    columnDefs: [
        {
            headerName: "Codice prodotto",
            field: "code",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Nome prodotto",
            field: "name",
            minWidth: 400,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Costo Unitario (CHF)",
            field: "priceFormatted",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Punto di riordino",
            field: "minimumStockQuantity",
            minWidth: 80,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Catalogo",
            field: "catalog.name",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Categoria",
            field: "category.name",
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/product-details/' + params.data.id} className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/edit-product/' + params.data.id} className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            minWidth: 220,
            suppressSizeToFit: true,
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function Products() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        ApiService.getProducts().then(response => response.json()).then(response => {
            setLoading(false);
            const products = response;
            if (products.length > 0) {
                data.rowData = products;
            }
        });
    }, []);

    return (<>

        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Prodotti e forniture</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate("/edit-product")
                        }}>
                    Crea prodotto
                </Button>
            </Grid>
        </Grid>

        {loading ?
            ''
            :

            <Grid container sx={{my: 3}}>
                <Grid item xs={12}>
                    <AgGrid
                        children={data}
                    />
                </Grid>
            </Grid>
        }
    </>);
}