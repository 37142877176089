import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

export default function EditCatalog() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [budget, setBudget] = useState(true);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            ApiService.getBudgetById(id).then(response => response.json()).then(response => {
                setBudget(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteFunction() {
        ApiService.deleteBudgetById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate("/functions");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function archiveFunction() {
        const users = budget.users.map(user => user.id);

        const body = {
            id: budget.id,
            name: budget.name,
            schoolYearId: budget.schoolYear.id,
            catalogId: budget.catalog.id,
            locationId: budget.location ? budget.location.id : undefined,
            users: users,
            hasLimit: budget.amount !== null,
            primarySchoolStudents: budget.primarySchoolStudents,
            elementarySchoolFirstCycleStudents: budget.elementarySchoolFirstCycleStudents,
            elementarySchoolSecondCycleStudents: budget.elementarySchoolSecondCycleStudents,
            arbitraryAmount: budget.arbitraryAmount,
            enabled: !budget.enabled
        }

        ApiService.updateBudget(body).then(response => response.json()).then(response => {
            if (response) {
                setBudget(response);
                navigate("/function-details/" + id);
            }
        })
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Funzione</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>

                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                            funzione: </Typography>{budget?.name}</Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Periodo: </Typography>{budget?.schoolYear?.name}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Catalogo: </Typography>{budget?.catalog?.name}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Sede: </Typography>{budget?.location?.name}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Utenti: </Typography>{budget?.users && budget?.users.length > 0 ? budget?.users.map(
                            (user) => (
                                <span>{user.firstName + ' ' + user.lastName + ' '}</span>
                            )) : ''}
                        </Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget attuale
                            (CHF): </Typography>{budget?.availableAmountFormatted}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget iniziale
                            (CHF): </Typography>{budget?.amountFormatted}</Typography>
                        {budget.amountFormatted !== 'Illimitato' ?
                            <> <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget
                                aggiuntivo
                                (CHF): </Typography>{budget?.arbitraryAmountFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>N. studenti
                                    SI: </Typography>{budget?.primarySchoolStudents}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>N. studenti SE
                                    1°
                                    ciclo: </Typography>{budget?.elementarySchoolFirstCycleStudents}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>N. studenti SE
                                    2°
                                    ciclo: </Typography>{budget?.elementarySchoolSecondCycleStudents}</Typography>
                            </>
                            :
                            ''
                        }
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <Stack spacing={1.25}>
                    <Button variant="contained" color="primary" fullWidth
                            onClick={() => navigate(('/edit-function/' + id))} disabled={!budget?.enabled}>
                        Modifica
                    </Button>
                    <Button variant="flip" fullWidth onClick={() => archiveFunction()}>
                        {budget?.enabled ? 'Archivia' : 'Ripristina'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => confirmOpen()}
                            disabled={!budget?.enabled}>
                        Elimina
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate(-1)
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteFunction} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questa funzione bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}