import './App.css';
import Routing from "./routing/Routing";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        type: 'light',
        common: {
            white: '#fff',
            black: '#212121'
        },
        primary: {
            main: '#c23052',
        },
        secondary: {
            main: '#333333',
        },
        background: {
            default: '#ffffff',
            paper: '#fafafa'
        },
        success: {
            main: '#4CAF50'
        },
        warning: {
            main: '#ff9800',
        },
        error: {
            main: '#f44336',
        },
        info: {
            main: '#0dcaf0',
        },
    },
    typography: {
        fontFamily: [
            'Nunito',
            'Helvetica',
            'Roboto',
            'sans-serif',
        ].join(','),
        button: {
            textTransform: 'initial'
        }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'flip'},
                    style: {
                        color: '#333333',
                        background: '#ffffff',
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                    },
                },
            ],
        },

    },

});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routing/>
        </ThemeProvider>
    )
}

export default App;