import {Box, Button, Grid, MenuItem, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import {NumericFormat} from "react-number-format";

export default function EditCoupon() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [description, setDescription] = useState(undefined);
    const [supplier, setSupplier] = useState(undefined);
    const [amount, setAmount] = useState(undefined);
    const [invoiceNumber, setInvoiceNumber] = useState(undefined);
    const [idComment, setIdComment] = useState(undefined);
    const [budget, setBudget] = useState(undefined);
    const [owner, setOwner] = useState(undefined);

    const [enabled, setEnabled] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    const [listOfFunctions, setListOfFunctions] = useState(undefined);
    const [listOfUsers, setListOfUsers] = useState([]);

    useEffect(() => {
        ApiService.getUsers().then(response => response.json()).then(response => {
            setListOfUsers(response);
        });

        if (id) {
            setIsEdit(true);

            ApiService.getCouponById(id).then(response => response.json()).then(response => {
                const coupon = response;
                setDescription(coupon.description);
                setSupplier(coupon.supplier);
                setAmount(coupon.amount / 100);
                setInvoiceNumber(coupon.invoiceNumber);
                setIdComment(coupon.idComment);
                setOwner(coupon.owner.id);
                ApiService.getBudgetsByUserId(coupon.owner.id).then(response => response.json()).then(response => {
                    const budgets = response;
                    if (budgets.budgets.length > 0) {
                        setListOfFunctions(budgets.budgets);
                        setBudget(coupon.budget.id);
                    }
                });
                setEnabled(coupon.enabled);
            })
        } else {
            setIsEdit(false);
        }

    }, [id])

    function saveCoupon() {
        if (id) {
            const body = {
                id: Number(id),
                description: description,
                supplier: supplier,
                amount: (Number(amount) * 100),
                invoiceNumber: invoiceNumber,
                idComment: idComment,
                budgetId: Number(budget),
                ownerId: owner,
                enabled: enabled
            }

            ApiService.updateCoupon(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/coupons")
                }
            })
        } else {
            const body = {
                description: description,
                supplier: supplier,
                amount: (Number(amount) * 100),
                invoiceNumber: invoiceNumber,
                idComment: idComment,
                budgetId: Number(budget),
                ownerId: owner,
                enabled: enabled
            }

            ApiService.createCoupon(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/coupons")
                }
            })
        }
    }

    function setUserForFunctions(selectedValue) {
        setOwner(selectedValue);
        ApiService.getBudgetsByUserId(selectedValue).then(response => response.json()).then(response => {
            const budgets = response;
            if (budgets.budgets.length > 0) {
                setListOfFunctions(budgets.budgets);
            }
        });
    }

    function checkMandatoryCouponFields() {
        return !(idComment && description && supplier && amount && owner && budget);
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica buono acquisto' : 'Nuovo buono acquisto'}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 4}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="idComment" label="ID Buono" value={idComment} defaultValue={idComment}
                                   variant="standard" fullWidth onChange={e => setIdComment(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required/>
                        <TextField id="description" label="Descrizione" value={description} defaultValue={description}
                                   variant="standard" fullWidth
                                   onChange={e => setDescription(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required/>
                        <TextField
                            select
                            id="ownerIdSelect"
                            label={'Seleziona utente'}
                            required
                            fullWidth
                            variant="standard"
                            value={owner || ''}
                            onChange={(e) => setUserForFunctions(e.target.value)}
                        >
                            {listOfUsers && listOfUsers.length > 0 ? listOfUsers.map((user) => (
                                <MenuItem key={user.id}
                                          value={user.id}>{user.firstName} {user.lastName}</MenuItem>)) : ''}
                        </TextField>
                        <TextField
                            select
                            id="functionIdSelect"
                            label={'Seleziona funzione'}
                            disabled={!owner}
                            required
                            fullWidth
                            variant="standard"
                            value={budget || ''}
                            onChange={(e) => setBudget(e.target.value)}
                        >
                            {listOfFunctions && listOfFunctions.length > 0 ? listOfFunctions.map((budget) => (
                                <MenuItem key={budget.id} value={budget.id}>{budget.name}</MenuItem>)) : ''}
                        </TextField>

                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       required
                                       decimalScale={2}
                                       customInput={TextField} id="amount"
                                       label="Valore (CHF)"
                                       defaultValue={amount}
                                       value={amount}
                                       variant="standard"
                                       onChange={e => setAmount(e.target.value)}
                                       fullWidth/>
                        <TextField id="supplier" label="Fornitore" value={supplier} defaultValue={supplier}
                                   variant="standard" fullWidth
                                   onChange={e => setSupplier(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required/>
                        <TextField id="invoiceNumber" label="N. Fattura" value={invoiceNumber}
                                   defaultValue={invoiceNumber}
                                   variant="standard" fullWidth
                                   onChange={e => setInvoiceNumber(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''}/>
                    </Stack>
                </Box>

                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/coupons')
                        }}>
                            Annulla
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary" disabled={checkMandatoryCouponFields()}
                                    onClick={() => {
                                        saveCoupon()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary" disabled={checkMandatoryCouponFields()}
                                    onClick={() => {
                                        saveCoupon()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
}