import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {enabledFormatter} from "../configuration/agGridFormatterFunctions";

const data = {
    columnDefs: [
        {
            headerName: "Nome catalogo",
            field: "name",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/catalog-details/' + params.data.id} className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/edit-catalog/' + params.data.id} className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function Catalogs() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        ApiService.getCatalogs().then(response => response.json()).then(response => {
            if (response) {
                setLoading(false);
                if (response.length > 0) {
                    data.rowData = response;
                } else {
                    data.rowData = [];
                }
            }

        });
    }, [])

    return (<>

        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Cataloghi</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate("/edit-catalog")
                        }}>
                    Crea catalogo
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{my: 3}}>
            <Grid item xs={12}>
                <AgGrid children={loading ? [] : data}/>
            </Grid>
        </Grid>
    </>);
}