import React, {useEffect, useState} from "react";
import Breadcrumb from "../components/Breadcrumb";
import {Button, Grid, Paper, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";

export default function LocationDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [location, setLocation] = useState(undefined);

    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        if (id) {
            ApiService.getLocationById(id).then(response => response.json()).then(response => {
                setLocation(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDialog(false);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };

    function deleteLocation() {
        ApiService.deleteLocationById(id).then(response => response.json()).then(response => {
            if (response) {
                if (response.success === true) {
                    navigate("/locations");
                } else {
                    setOpenDialog(false);
                    setOpenSnackBar(true);
                }
            }
        })
    }

    function archiveLocation() {
        const body = {
            id: location.id,
            name: location.name,
            street: location.street,
            zip: location.zip,
            city: location.city,
            enabled: !location.enabled
        }

        ApiService.updateLocation(body).then(response => response.json()).then(response => {
            if (response) {
                setLocation(response);
                navigate("/location-details/" + id)
            }
        })
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Sede</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                            sede: </Typography>{location?.name}</Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Indirizzo: </Typography>{location?.street}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>CAP: </Typography>{location?.zip}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Città: </Typography>{location?.city}
                        </Typography>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={2}>
                <Stack spacing={1.25}>
                    <Button variant="contained" color="primary" onClick={() => navigate(('/edit-location/' + id))}
                            fullWidth disabled={!location?.enabled}>
                        Modifica
                    </Button>
                    <Button variant="flip" fullWidth onClick={() => archiveLocation()}>
                        {location?.enabled ? 'Archivia' : 'Ripristina'}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => confirmOpen()}
                            disabled={!location?.enabled}
                            fullWidth>
                        Elimina
                    </Button>
                </Stack>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 5}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/locations')
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={handleClose} deleteFunction={deleteLocation} title={'Attenzione!'}
                     body={'Sicuro che vuoi eliminare l’elemento?'} closeLabel={'No'} successLabel={'Sì, elimina'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={handleCloseSnackBar} type={'warning'} title={'Attenzione!'}
                       duration={10000}
                       body={'Per poter eliminare questa sede bisogna rimuovere tutte le relazioni associate ad esso.'}/>
    </>);
}