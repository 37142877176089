import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {getUser} from "../services/AuthService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";

export default function Orders() {
    const navigate = useNavigate();
    const [columnDefs] = useState([
        {
            headerName: "ID Ordine",
            field: "id",
            minWidth: 180,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Utente",
            field: "user.fullName",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Creato da",
            field: "createdBy",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Periodo",
            field: "budget.schoolYear.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Totale (CHF)",
            field: "totalFormatted",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Sede fornitura",
            field: "deliveryLocation.name",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Data creazione",
            field: "createdAtFormatted",
            sortable: true,
            minWidth: 160,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (<Grid container spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link className={'muiBtn muiBtnPrimary'} to={'/order-details/' + params.data.id}>
                            Dettaglio
                        </Link>
                    </Grid>
                    {params.data.status !== 'IN_PROGRESS' ? '' :
                        <Grid item>
                        <Link
                            className={'muiBtn muiBtnSecondary'}
                            to={'/order-info/' + params.data.id}
                        >
                            Modifica
                        </Link>
                    </Grid>}

                    {params.data.status === 'SUBMITTED' ?
                        <Grid item>
                            <Link
                                className={'muiBtn muiBtnSecondary'}
                                to={'/order-return/' + params.data.id}
                            >
                                Modifica
                            </Link>
                        </Grid>
                        :
                        ''
                    }

                </Grid>)
            }, suppressSizeToFit: true, minWidth: 200
        },]);

    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });

    const [catalogs, setCatalogs] = useState(undefined);
    const [selectedCatalog, setSelectedCatalog] = useState('');
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clickedButton, setClickedButton] = useState('SUBMITTED');

    const onClickStatus = useCallback((status, catalog) => {
        fetchOrders(status, catalog === '' ? undefined : catalog).then(response => {
            if (response) {
                setRowData(response);
            }
        });
    }, []);

    useEffect(() => {
        const user = getUser();
        let role = '';
        if (user) {
            role = user.ROLE[0].authority;
            if (role === 'USER') {
                navigate('/home-user')
            }
            if (role === 'SECRETARIAT') {
                navigate('/home-secretary')
            }
        }

        setLoading(true);
        ApiService.getCatalogs().then(response => response.json()).then(response => {
            const catalogs = response;
            if (catalogs.length > 0) {
                setCatalogs(catalogs);
                setSelectedCatalog(catalogs[0].id);
                ApiService.getOrdersByStatus('SUBMITTED', catalogs[0].id).then(response => response.json()).then(response => {
                    setLoading(false);
                    const orders = response;
                    if (orders.orders.length > 0) {
                        setRowData(orders.orders);
                    }
                });
            }
        });


        // eslint-disable-next-line
    }, [navigate]);

    const fetchOrders = async (status, catalog) => {
        setClickedButton(status);
        return await ApiService.getOrdersByStatus(status, catalog === '' ? undefined : catalog).then((response) => response.json()).then(response => {
            setLoading(false);
            if (response.orders.length > 0) {
                return response.orders;
            } else {
                return [];
            }

        });
    }

    function fetchOrdersByCatalog(itemSelected) {
        setSelectedCatalog(itemSelected);
        fetchOrders(clickedButton, itemSelected === '' ? undefined : itemSelected).then(response => {
            setRowData(response);
        });
    }

    return (<div>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid xs="auto">
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Homepage - Ordini</Typography>
            </Grid>
            <Grid xs="auto">
                <Button variant="contained" color="primary"
                        onClick={() => {
                            navigate('/order-info')
                        }}
                >
                    Crea ordine
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}}>
            <Grid item xs={12} md={8} lg={4}>
                <Box
                    sx={{
                        background: '#EFEFEF',
                        p: 1,
                        mb: 2
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                select
                                id="selectedType"
                                value={selectedCatalog || ''}
                                label={'Seleziona un catalogo'}
                                onChange={(e) => fetchOrdersByCatalog(e.target.value)}
                                fullWidth
                                variant="standard"
                            >
                                {
                                    catalogs?.map((catalog) => (
                                        <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <Box
            sx={{
                borderBottom: '1px solid rgba(0,0,0,0.12)',
                mt: 4,
                mb: 2
            }}
        >
            <Button className={clickedButton === 'SUBMITTED' ? 'active' : ''} variant="text" color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus('SUBMITTED', selectedCatalog)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini da approvare
            </Button>
            <Button className={clickedButton === 'IN_PROGRESS' ? 'active' : ''} variant="text" color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus('IN_PROGRESS', selectedCatalog)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini in stesura
            </Button>
            <Button className={clickedButton === 'APPROVED' ? 'active' : ''} variant="text" color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus('APPROVED', selectedCatalog)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini da evadere
            </Button>
            <Button className={clickedButton === 'PROCESSED' ? 'active' : ''} variant="text" color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus('PROCESSED', selectedCatalog)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini evasi
            </Button>
            <Button className={clickedButton === 'ABORTED' ? 'active' : ''} variant="text" color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus('ABORTED', selectedCatalog)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini annullati
            </Button>
        </Box>

        {!loading ? <>
            <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                <div style={{width: '100%', height: '100%'}}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        pagination={true}
                        paginationPageSize={25}
                        singleClickEdit={true}
                        domLayout={'autoHeight'}
                        localeText={agGridItalianTranslation}
                    >
                    </AgGridReact>
                </div>
            </div>
        </> : ''}
    </div>);
}