import {Button, Grid, Paper} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import AgGrid from "../components/AgGrid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import ApiService from "../services/ApiService";
import {enabledFormatter, statusFormatter} from "../configuration/agGridFormatterFunctions";
import {getUser} from "../services/AuthService";

const dataOrders = {
    columnDefs: [
        {
            headerName: "Creato da",
            field: "createdBy",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Periodo",
            field: "budget.schoolYear.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Totale (CHF)",
            field: "totalFormatted",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Sede fornitura",
            field: "budget.location?.name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data creazione",
            field: "createdAtFormatted",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Stato ordine",
            field: "statusLabel",
            cellRenderer: statusFormatter,
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'} to={'/order-user-details/' + params.data.id}>
                                Dettaglio
                            </Link>
                        </Grid>
                        {params.data.status !== 'IN_PROGRESS' ?
                            ''
                            :
                            <Grid item>
                                <Link
                                    className={'muiBtn muiBtnSecondary'}
                                    to={'/order-info/' + params.data.id}
                                >
                                    Modifica
                                </Link>
                            </Grid>
                        }

                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

const dataCoupons = {
    columnDefs: [
        {
            headerName: "ID Buono",
            field: "idComment",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 250,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 250,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Utente",
            field: "owner.fullName",
            minWidth: 250,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Valore (CHF)",
            field: "amountFormatted",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Annullato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'} to={'/coupon-details/' + params.data.id}>
                                Dettaglio
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link
                                    className={'muiBtn muiBtnSecondary'}
                                    to={'/edit-coupon/' + params.data.id}
                                >
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

const dataFunctions = {
    columnDefs: [
        {
            headerName: "Nome funzione",
            field: "name",
            minWidth: 250,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget attuale (CHF)",
            field: "availableAmountFormatted",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget iniziale (CHF)",
            field: "amountFormatted",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget aggiuntivo (CHF)",
            field: "arbitraryAmountFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SI",
            field: "primarySchoolStudents",
            minWidth: 150,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 1° ciclo",
            field: "elementarySchoolFirstCycleStudents",
            minWidth: 150,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 2° ciclo",
            field: "elementarySchoolSecondCycleStudents",
            minWidth: 15,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function HomeSecretary() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const user = getUser();

    useEffect(() => {
        setLoading(true);
        ApiService.getOrders().then(response => response.json()).then(response => {
            const orders = response;
            if (orders.length > 0) {
                dataOrders.rowData = orders;
            }
            ApiService.getCoupons().then(response => response.json()).then(response => {
                const coupons = response;
                if (coupons.length > 0) {
                    dataCoupons.rowData = coupons;
                }
                ApiService.getBudgetsByUserId(user.id).then(response => response.json()).then(response => {
                    setLoading(false);
                    const budgets = response;
                    if (budgets.budgets.length > 0) {
                        dataFunctions.rowData = budgets.budgets;
                    }
                });
            });

        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container sx={{mt: 2}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid xs={12} md={6}>
                            <Typography variant="h5" component="h2"
                                        sx={{fontWeight: '500', mb: {xs: 2, sm: 0}}}>Homepage</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Paper
                component={'div'}
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderTop: '2px solid #c23052',
                    p: 2,
                    my: 3
                }}
            >
                <Grid container columnSpacing={3} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Ordini</Typography>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button variant={'contained'} color={'primary'} onClick={() => {
                                navigate('/order-info')
                            }}>
                                Crea ordine
                            </Button>
                        </Box>
                    </Grid>
                    {!loading ?
                        <Grid item xs={12}>
                            <Box sx={{mt: 2}}>
                                <AgGrid children={dataOrders}/>
                            </Box>
                        </Grid>
                        :
                        ''
                    }
                </Grid>
            </Paper>

            <Paper
                component={'div'}
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderTop: '2px solid #c23052',
                    p: 2,
                    my: 5
                }}
            >
                <Grid container columnSpacing={3} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Buoni
                            acquisto</Typography>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button variant={'contained'} color={'primary'} onClick={() => {
                                navigate('/edit-coupon')
                            }}>
                                Nuovo buono
                            </Button>
                        </Box>
                    </Grid>
                    {!loading ?
                        <Grid item xs={12}>
                            <Box sx={{mt: 2}}>
                                <AgGrid children={dataCoupons}/>
                            </Box>
                        </Grid>
                        :
                        ''
                    }
                </Grid>
            </Paper>

            <Grid container columnSpacing={3} justifyContent={'space-between'} sx={{my: 5}}>
                <Grid item>
                    <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Le mie funzioni</Typography>
                </Grid>
                {!loading ?
                    <Grid item xs={12}>
                        <Box sx={{mt: 2}}>
                            <AgGrid children={dataFunctions}/>
                        </Box>
                    </Grid>
                    :
                    ''
                }
            </Grid>
        </>
    );
}