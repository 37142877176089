import {Alert, AlertTitle, Snackbar} from "@mui/material";
import React from "react";

export default function SnackBarAlert({isOpen, handleClose, type, title, body, duration}) {
    return (
        <>
            <Snackbar open={isOpen} autoHideDuration={duration} onClose={handleClose}
                      anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                <Alert severity={type} isOpen={true} onClose={handleClose}>
                    <AlertTitle>{title}</AlertTitle>
                    {body}
                </Alert>
            </Snackbar>
        </>
    )
}
