import {useState} from "react";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Outlet} from "react-router-dom";
import ProfilePopover from "./ProfilePopover";
import logo from "../assets/img/lvga-logo.png";
import ListItems from "../configuration/ListItems";
import BottomBar from "./BottomBar";

const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function PersistentDrawerLeft() {
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}
                    sx={{
                        background: '#FFFFFF',
                        boxShadow: '0px -12px 20px rgba(0, 0, 0, 0.75)',
                    }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{mr: 2, ...(open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <IconButton
                        onClick={handleDrawerClose}
                        color="secondary"
                        sx={{mr: 2, ...(!open && {display: 'none'})}}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <ProfilePopover/>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: "#212121"
                    }
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        py: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start'
                    }}
                >
                    <img src={logo} alt="lvga logo" width="48" height="auto"/>
                    <div style={{fontSize: 24, fontWeight: 500, marginLeft: '15px', color: '#ffffff'}}>RiMa</div>
                </Box>
                <List className={'white-text'}><ListItems/></List>
            </Drawer>
            <Main open={open}
                  sx={{marginTop: '64px', minHeight: 'calc(100vh - 64px)', display: 'flex', flexDirection: 'column'}}>
                <Box component={'div'} sx={{px: 3, pt: 3, marginBottom: '42px'}}>
                    <Outlet/>
                </Box>

                <Box component={'div'} sx={{marginTop: 'auto'}}>
                    <BottomBar/>
                </Box>
            </Main>
        </Box>
    );
}
