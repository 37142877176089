import React, {useEffect, useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {enabledFormatter} from "../configuration/agGridFormatterFunctions";

const data = {
    columnDefs: [
        {
            headerName: "Nome categoria",
            field: "name",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/category-details/' + params.data.id} className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/edit-category/' + params.data.id} className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export default function Catalogs() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        ApiService.getCategories().then(response => response.json()).then(response => {
            setLoading(false);
            const categories = response;
            if (categories.length > 0) {
                data.rowData = categories;
            }
        });
    }, [])

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Categorie prodotti</Typography>
            </Grid>
            <Grid xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate("/edit-category")
                        }}>
                    Crea categoria
                </Button>
            </Grid>
        </Grid>
        {loading ?
            ''
            :
            <Grid container sx={{my: 3}}>
                <Grid item xs={12}>
                    <AgGrid children={data}/>
                </Grid>
            </Grid>
        }
    </>);
}