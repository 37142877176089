import React from 'react';
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import AgGrid from "./AgGrid";

const data = {
    columnDefs: [
        {headerName: "Nome utente", field: "username", minWidth: 180},
        {headerName: "Funzione", field: "function", minWidth: 150},
        {headerName: "Budget iniziale (CHL)", field: "initial_amount", minWidth: 100},
        {
            headerName: "Importo speso (CHF)",
            field: "spent_amount",
            minWidth: 100
        },
        {headerName: "Budget attuale (CHF)", field: "current_amount", minWidth: 100}],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: [{
        username: 'Monitor Cryms',
        function: 'Religione',
        initial_amount: '1000',
        spent_amount: '800',
        current_amount: '200'
    }]
}

export default function CostLimit() {
    // const navigate = useNavigate();

    /*const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [value, setValue] = React.useState('null');*/

    let age;
    return (<>

        <Grid container sx={{mt: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Limite Spesa</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}}>
            <Grid item xs={12} md={8} lg={6}>
                <Box
                    sx={{
                        background: '#EFEFEF',
                        p: 1,
                        mb: 2
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={7} sm={8}>
                            <TextField
                                select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={age} /*  onChange={handleChange}*/
                                label="Funzione"
                                variant='standard'
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={5} sm={4}>
                            <Button variant="contained" color="primary" fullWidth sx={{mt: 1}} disabled>
                                Genera report
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs={12} md={8} lg={6}>
                <Box
                    sx={{
                        background: '#EFEFEF',
                        p: 1,
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={7} sm={8}>
                            <TextField
                                id="outlined-basic"
                                label="Limite"
                                variant="standard"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={5} sm={4}>
                            <Button variant="contained" color="primary" fullWidth sx={{mt: 1}} disabled>
                                Genera report
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}}>
            <Grid item xs={12}>
                <AgGrid children={data}/>
            </Grid>
        </Grid>
    </>);
}