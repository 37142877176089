import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import {LicenseManager} from "ag-grid-enterprise";

LicenseManager.setLicenseKey("CompanyName=Municipio di Lugano,LicensedGroup=EDU-ADMIN,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-034096,SupportServicesEnd=9_November_2023_[v2]_MTY5OTQ4ODAwMDAwMA==3822e4d02890e52bbc8be16ca1cc9974");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
