import {useState} from "react";
import logo from "../assets/img/lvga-logo.png";
import ListItems from "../configuration/ListItems";
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import {Outlet} from "react-router-dom";
import BottomBar from "./BottomBar";
import ProfilePopover from "./ProfilePopover";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} - 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} - 1px)`,
    },
});

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    width: `calc(100% - ${theme.spacing(8)} + 1px)`,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function NavigationBar() {
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar position="fixed" open={open}
                    sx={{
                        background: '#FFFFFF',
                        boxShadow: '0px -12px 20px rgba(0, 0, 0, 0.75)',
                    }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <IconButton
                        color="secondary"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <IconButton
                        color="secondary"
                        onClick={handleDrawerClose}
                        sx={{
                            ...(!open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>

                    <ProfilePopover/>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={open}
                PaperProps={{
                    sx: {
                        backgroundColor: "#212121"
                    }
                }}
            >
                <Box
                    sx={{
                        px: 2,
                        py: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start'
                    }}
                >
                    <img src={logo} alt="lvga logo"

                         style={{
                             width: open ? '48px' : '32px',
                             height: "auto"
                         }}
                    />
                    <Typography color={'white'}
                                sx={{
                                    fontSize: 24,
                                    fontWeight: 500,
                                    marginLeft: '15px',
                                    opacity: open ? 1 : 0,
                                }}
                    >
                        RiMa
                    </Typography>
                </Box>
                <List className={'white-text'}>
                    <ListItems open={open}/>
                </List>

            </Drawer>
            <Box component="main" sx={{
                flexGrow: 1,
                marginTop: '64px',
                minHeight: 'calc(100vh - 64px)',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Box component={'div'} sx={{px: 3, pt: 3, marginBottom: '42px'}}>
                    <Outlet/>
                </Box>

                <Box component={'div'} sx={{marginTop: 'auto'}}>
                    <BottomBar/>
                </Box>
            </Box>
        </Box>
    );
}