import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";

export default function EditCategory() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [name, setName] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [enabled, setEnabled] = useState(true);

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            ApiService.getCategoryById(id).then(response => response.json()).then(response => {
                const category = response;
                setName(category.name);
                if (category.description) {
                    setDescription(category.description);
                }
                setEnabled(category.enabled);
            })
        } else {
            setIsEdit(false);
        }
    }, [id])

    function saveCategory() {
        if (id) {
            const body = {
                id: id,
                name: name,
                description: description,
                enabled: enabled
            }
            ApiService.updateCategory(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/categories")
                }
            })
        } else {
            const body = {
                name: name,
                description: description
            }
            ApiService.createCategory(body).then(response => response.json()).then(response => {
                if (response) {
                    navigate("/categories")
                }
            })
        }
    }

    function checkMandatoryCategoryFields() {
        return !(name);
    }


    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Breadcrumb/>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Categoria' : 'Nuova Categoria'}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 4}}>
            <Grid item xs={12} md={4}>
                <Box component={'form'}>
                    <TextField id="name" label="Nome categoria" size="small" value={name} defaultValue={name}
                               variant="standard"
                               fullWidth
                               onChange={e => setName(e.target.value)} InputLabelProps={isEdit ? {shrink: true} : ''}
                               required/>
                    <TextField id="description" label="Descrizione" size="small" value={description} defaultValue={description}
                               variant="standard"
                               fullWidth
                               onChange={e => setDescription(e.target.value)} InputLabelProps={isEdit ? {shrink: true} : ''}/>
                </Box>
                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/categories')
                        }}>
                            Annulla
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary" disabled={checkMandatoryCategoryFields()}
                                    onClick={() => {
                                        saveCategory()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary" disabled={checkMandatoryCategoryFields()}
                                    onClick={() => {
                                        saveCategory()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    </>);
}